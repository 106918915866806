import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import type { AxiosError } from 'axios';

import useAuth from 'contexts/AuthContext';
import useModal from 'contexts/ModalContext';

import IconSVG from 'components/common/IconSVG';
import Loader from 'components/common/LoaderScreen/Loader';
import ModalWindow from 'components/common/ModalWindow';

import { IconsNames } from 'constants/icons';
import { API_ENDPOINTS } from 'constants/endpoints';

import styles from './index.module.scss';

type LeadStatisticsData = {
  disqualified: number;
  pending: number;
  qualified: number;
};

const LeadStatistics = () => {
  const { t } = useTranslation();
  const { axios } = useAuth();
  const { openModal } = useModal();

  const { data: leadStatistics, isLoading } = useQuery(
    ['leadStatistics'],
    async () => axios.get<LeadStatisticsData>(API_ENDPOINTS.leadStatistics),
    {
      onError: (err: AxiosError) => {
        openModal({
          Content: (
            <ModalWindow
              title={t('common.error.something-went-wrong')}
              errorMessage={err.message}
            />
          ),
        });
      },
    }
  );

  return (
    <div className={styles.statisticsWrapper}>
      <h4>{t('dashboard.lead-statistics')}</h4>
      <div className={styles.statRow}>
        <div className={styles.item}>
          <div className={styles.statDataWrapper}>
            {isLoading ? (
              <Loader className={styles.loader} />
            ) : (
              <span className={styles.value}>
                {leadStatistics?.data?.pending?.toLocaleString() || 0}
              </span>
            )}
            <span className={styles.label}>{t('common.field.pending')}</span>
          </div>

          <IconSVG name={IconsNames.timer} className={styles.icon} />
        </div>
        <div className={styles.item}>
          <div className={styles.statDataWrapper}>
            {isLoading ? (
              <Loader className={styles.loader} />
            ) : (
              <span className={styles.value}>
                {leadStatistics?.data?.qualified?.toLocaleString() || 0}
              </span>
            )}
            <span className={styles.label}>{t('common.field.qualified')}</span>
          </div>
          <IconSVG name={IconsNames.like} className={styles.icon} />
        </div>
        <div className={styles.item}>
          <div className={styles.statDataWrapper}>
            {isLoading ? (
              <Loader className={styles.loader} />
            ) : (
              <span className={styles.value}>
                {leadStatistics?.data.disqualified?.toLocaleString() || 0}
              </span>
            )}
            <span className={styles.label}>
              {t('common.field.disqualified')}
            </span>
          </div>
          <IconSVG name={IconsNames.dislike} className={styles.icon} />
        </div>
      </div>
    </div>
  );
};

export default LeadStatistics;
