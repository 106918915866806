import cn from 'classnames';
import { useTable } from 'react-table';
import { useTranslation } from 'react-i18next';
import { Fragment } from 'react';

import type { TableOptions, PluginHook } from 'react-table';

import styles from './index.module.scss';

type Props = {
  wrapperClassName?: string;
  headClassName?: string;
  rowClassName?: string;
  tableOptions: TableOptions<{}>;
  plugins?: Array<PluginHook<{}>>;
  noDataComponent?: React.ReactNode;
  isFirstColumnFixed?: boolean;
  isEvenRowGrey?: boolean;
};

const Table = ({
  wrapperClassName,
  headClassName,
  rowClassName,
  tableOptions,
  plugins,
  noDataComponent,
  isFirstColumnFixed = false,
  isEvenRowGrey,
}: Props) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
  } = useTable(tableOptions, ...(plugins || []));
  const { t } = useTranslation();

  return (
    <div className={cn(styles.wrapper, wrapperClassName)}>
      <table {...getTableProps()} className={styles.table}>
        <thead className={cn(styles.head, headClassName)}>
          {headerGroups.map((headerGroup, index) => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              key={index}
              className={cn(styles.row, rowClassName)}
            >
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps()}
                  key={column.getHeaderProps().key}
                  className={cn(styles.th, {
                    [styles.fixed]: isFirstColumnFixed,
                  })}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()} className={styles.body}>
          {!rows.length && (
            <tr className={cn(styles.row, rowClassName)}>
              <td
                colSpan={visibleColumns.length}
                className={cn(styles.td, styles.empty)}
              >
                {noDataComponent
                  ? noDataComponent
                  : t('common.error.empty-data')}
              </td>
            </tr>
          )}
          {rows.map(row => {
            prepareRow(row);

            return (
              <Fragment key={row.getRowProps().key}>
                <tr
                  className={cn(styles.row, rowClassName, {
                    [styles.grey]: isEvenRowGrey,
                  })}
                  {...row.getRowProps()}
                  key={row.getRowProps().key}
                >
                  {row.cells.map(cell => (
                    <td
                      {...cell.getCellProps()}
                      key={cell.getCellProps().key}
                      className={cn(styles.td, {
                        [styles.fixed]: isFirstColumnFixed,
                      })}
                    >
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              </Fragment>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
