import React from 'react';
import { useTranslation } from 'react-i18next';
import Select, { components } from 'react-select';
import cn from 'classnames';

import type {
  DropdownIndicatorProps,
  GroupBase,
  SingleValue,
} from 'react-select';
import { MatchIOFields } from 'types/models';

import IconSVG from 'components/common/IconSVG';

import { authSelectStyles } from 'utils/selectStyles';

import { MatchIO } from 'constants/constants';
import { IconsNames } from 'constants/icons';

import styles from './index.module.scss';

type Props = {
  value: { value: MatchIO; label: string } | null;
  name: MatchIOFields;
  onChangeMatchIOStatus: (name: MatchIOFields, value: MatchIO) => void;
  placeholder?: string;
  isDisabled?: boolean;
};

type SelectMatchIOOption = {
  value: MatchIO | null;
  label: string;
};

const valueWithoutUnableToVerify = [
  MatchIOFields.company_tal_status,
  MatchIOFields.company_sal_status,
  MatchIOFields.profile_dual_employment_status,
  MatchIOFields.profile_num_month_in_current_company_status,
  MatchIOFields.profile_qualified_contacts_per_company_status,
];

const MatchIOSelect = ({
  value,
  name,
  onChangeMatchIOStatus,
  placeholder,
  isDisabled = false,
}: Props): JSX.Element => {
  const { t } = useTranslation();

  const isWithoutUnableToVerify = valueWithoutUnableToVerify.includes(name);

  const primaryOptions = [
    { label: t('common.field.valid'), value: MatchIO.valid },
    { label: t('common.field.invalid'), value: MatchIO.invalid },
  ];

  const selectOptions = isWithoutUnableToVerify
    ? primaryOptions
    : [
        ...primaryOptions,
        {
          label: t('common.field.unable_to_verify'),
          value: MatchIO.unable_to_verify,
        },
      ];

  const handleValueChange = (val: SingleValue<SelectMatchIOOption>) => {
    onChangeMatchIOStatus(name, val?.value || MatchIO.unable_to_verify);
  };

  const singleValueComponent = ({ data }: any) => {
    const icon =
      data.value === MatchIO.valid
        ? IconsNames.check
        : data.value === MatchIO.invalid
        ? IconsNames.closeCircle
        : IconsNames.questionCircle;
    return (
      <div className={styles.inputWrapper}>
        {icon && (
          <IconSVG
            name={icon}
            className={cn(styles.icon, {
              [styles.valid]: data.value === MatchIO.valid,
              [styles.invalid]: data.value === MatchIO.invalid,
              [styles.unableToVerify]: data.value === MatchIO.unable_to_verify,
            })}
          />
        )}
        <span>{data.label}</span>
      </div>
    );
  };

  const DropdownIndicator = (
    props: DropdownIndicatorProps<
      SelectMatchIOOption,
      boolean,
      GroupBase<SelectMatchIOOption>
    >
  ) => {
    return (
      <components.DropdownIndicator {...props}>
        <IconSVG name={IconsNames.selectArrows} />
      </components.DropdownIndicator>
    );
  };

  return (
    <Select<SelectMatchIOOption>
      value={value}
      onChange={handleValueChange}
      options={selectOptions}
      styles={authSelectStyles}
      placeholder={placeholder || t('common.field.not-applicable')}
      components={{ DropdownIndicator, SingleValue: singleValueComponent }}
      className={styles.select}
      isDisabled={isDisabled}
      menuPortalTarget={document.body}
    />
  );
};

export default MatchIOSelect;
