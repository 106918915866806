import React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { usePopperTooltip } from 'react-popper-tooltip';

import IconSVG from 'components/common/IconSVG';

import { IconsNames } from 'constants/icons';

import styles from './index.module.scss';

type Props = { message: string; className?: string; maxErrorLength?: number };

const Error = ({ message, className, maxErrorLength }: Props) => {
  const { t } = useTranslation();
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip();

  const isTooltipNeeded = maxErrorLength && t(message).length > maxErrorLength;
  const errorMessage = isTooltipNeeded
    ? `${t(message).slice(0, maxErrorLength)}...`
    : t(message || '');

  return (
    <>
      <p
        className={cn(styles.error, className)}
        ref={isTooltipNeeded ? setTriggerRef : undefined}
      >
        {message && <IconSVG name={IconsNames.error} role="alert" />}
        {errorMessage}
      </p>

      {isTooltipNeeded && visible && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({
            className: cn('tooltip-container', styles.tooltip),
          })}
        >
          {t(message)}
          <div {...getArrowProps({ className: 'tooltip-arrow' })} />
        </div>
      )}
    </>
  );
};

export default Error;
