import React, { InputHTMLAttributes, useRef, useEffect, useState } from 'react';

import type { ControllerRenderProps } from 'react-hook-form';

import styles from './index.module.scss';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  formSettings: any;
  field: Partial<ControllerRenderProps>;
}

const findFieldByType = (
  type: string,
  array: google.maps.GeocoderAddressComponent[]
) => array.find(field => field.types.includes(type));

const AddressField = ({ formSettings, field }: Props) => {
  const [autocomplete, setAutocomplete] =
    useState<google.maps.places.Autocomplete | null>(null);
  const ref = useRef(null);

  const handlePlaceSelect = () => {
    formSettings.clearErrors([
      'company_address',
      'company_country_code',
      'company_state',
      'company_city',
    ]);
    formSettings.resetField('company_address');
    formSettings.resetField('company_country_code');
    formSettings.resetField('company_state');
    formSettings.resetField('company_city');
    const address = autocomplete?.getPlace().address_components;
    if (address) {
      const streetAddress = `${
        findFieldByType('street_number', address)?.long_name || ''
      } ${findFieldByType('route', address)?.long_name}`;
      formSettings.setValue('company_address', streetAddress);
      formSettings.setValue(
        'company_city',
        findFieldByType('locality', address)?.long_name ||
          findFieldByType('postal_town', address)?.long_name ||
          findFieldByType('administrative_area_level_2', address)?.long_name
      );
      const country = findFieldByType('country', address);
      const countryCode = country?.short_name;
      formSettings.setValue('company_country_code', {
        value: country?.short_name,
        label: country?.long_name,
      });
      if (countryCode === 'US' || countryCode === 'CA') {
        formSettings.setValue('company_state', {
          value: findFieldByType('administrative_area_level_1', address)
            ?.long_name,
          label: findFieldByType('administrative_area_level_1', address)
            ?.long_name,
        });
      }
    }
  };

  useEffect(() => {
    if (ref.current) {
      setAutocomplete(
        new google.maps.places.Autocomplete(ref.current, {
          fields: ['address_components'],
          types: ['address'],
        })
      );
    }
  }, [ref.current]);

  useEffect(() => {
    if (autocomplete && typeof autocomplete.addListener === 'function') {
      autocomplete.addListener('place_changed', handlePlaceSelect);
    }
  }, [autocomplete]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    formSettings.setValue('company_address', e.currentTarget.value);
  };

  return (
    <input
      className={styles.input}
      type="text"
      autoComplete="new-address-input"
      ref={ref}
      value={field.value || ''}
      onChange={handleInputChange}
      placeholder=""
    />
  );
};

export default AddressField;
