import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { GoogleOAuthProvider } from '@react-oauth/google';

import App from 'components/App';

import { LoaderScreenProvider } from 'contexts/LoaderScreenContext';
import { AuthProvider } from 'contexts/AuthContext';
import { ModalProvider } from 'contexts/ModalContext';

import 'i18n';
import '@formatjs/intl-displaynames/polyfill';
import '@formatjs/intl-displaynames/locale-data/en';
import '@formatjs/intl-getcanonicallocales/polyfill';
import '@formatjs/intl-locale/polyfill';

import 'assets/styles/index.scss';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <Router>
      <GoogleOAuthProvider
        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ''}
      >
        <QueryClientProvider client={queryClient}>
          <LoaderScreenProvider>
            <ModalProvider>
              <AuthProvider>
                <App />
                <ReactQueryDevtools initialIsOpen={false} />
              </AuthProvider>
            </ModalProvider>
          </LoaderScreenProvider>
        </QueryClientProvider>
      </GoogleOAuthProvider>
    </Router>
  </React.StrictMode>
);
