import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

import { MatchIOFields, MatchIOStatuses, QCofTheLeadData } from 'types/models';
import type { ReactI18NextChild } from 'react-i18next';

import { authSelectStyles } from 'utils/selectStyles';

import Table from 'components/common/Table';
import MatchIOSelect from 'components/QCSessionContent/MatchIOSelect';
import ButtonMoreWithPopup from 'components/common/ButtonMoreWithPopup';
import { DropdownIndicator } from 'components/AuthForm/InputField';

import { MatchIO, NoValueFromBE } from 'constants/constants';
import { MaxLinkLength } from 'components/QCSessionContent/PersonCheck';

import styles from './index.module.scss';

type Props = {
  data: QCofTheLeadData;
  onChangeStatus: (name: MatchIOFields, value: MatchIO) => void;
  statuses: MatchIOStatuses;
};

enum TableAccessors {
  rowName = 'rowName',
  actualData = 'actualData',
  campaignSpecification = 'campaignSpecification',
  report = 'report',
  matchIO = 'matchIO',
}

const MaxListNumber = 5;

export const renderList = (list: string[] | ReactI18NextChild[]) => {
  const isLongList = list.length > MaxListNumber;
  const cellData = (isLongList ? list.slice(0, MaxListNumber) : list).map(
    (item: string | ReactI18NextChild, index: number) => (
      <div key={`${item}_${index}`} className={styles.listItem}>
        {item}
      </div>
    )
  );

  return (
    <>
      {cellData}
      {isLongList && (
        <ButtonMoreWithPopup
          popupContentList={list.slice(MaxListNumber, list.length)}
          buttonText={`+${list.length - MaxListNumber}`}
        />
      )}
    </>
  );
};

const CompanyCheck = ({
  data,
  statuses,
  onChangeStatus,
}: Props): JSX.Element => {
  const { t } = useTranslation();

  const renderLink = (href: string) =>
    href === NoValueFromBE ? (
      href
    ) : (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        className={styles.link}
        key={href}
      >
        {href.length > MaxLinkLength
          ? `${href.slice(0, MaxLinkLength)}...`
          : href}
      </a>
    );

  const rowsData = useMemo(() => {
    return [
      {
        [TableAccessors.rowName]: t('common.field.link'),
        [TableAccessors.actualData]: renderLink(
          data.third_party_data.company_link
        ),
        [TableAccessors.campaignSpecification]:
          typeof data.campaign_specification.company_link === 'string'
            ? data.campaign_specification.company_link
            : renderList(
                data.campaign_specification.company_link.map(item =>
                  renderLink(item)
                )
              ),
        [TableAccessors.report]: renderLink(data.lead_data.company_link),
        [TableAccessors.matchIO]: null,
      },
      {
        [TableAccessors.rowName]: t('common.field.name'),
        [TableAccessors.actualData]: data.third_party_data.company_name,
        [TableAccessors.campaignSpecification]:
          typeof data.campaign_specification.company_name === 'string'
            ? data.campaign_specification.company_name
            : renderList(data.campaign_specification.company_name),
        [TableAccessors.report]: data.lead_data.company_name,
        [TableAccessors.matchIO]: {
          value: statuses[MatchIOFields.company_name_status],
          name: MatchIOFields.company_name_status,
        },
      },
      {
        [TableAccessors.rowName]: t('common.field.sizeActual'),
        [TableAccessors.actualData]: data.third_party_data.company_size_actual,
        [TableAccessors.campaignSpecification]:
          data.campaign_specification.company_size_actual,
        [TableAccessors.report]: data.lead_data.company_size_actual,
        [TableAccessors.matchIO]: {
          value: statuses[MatchIOFields.company_size_actual_status],
          name: MatchIOFields.company_size_actual_status,
        },
      },
      {
        [TableAccessors.rowName]: t('common.field.sizeRange'),
        [TableAccessors.actualData]: data.third_party_data.company_size_range,
        [TableAccessors.campaignSpecification]:
          typeof data.campaign_specification.company_size_range === 'string'
            ? data.campaign_specification.company_size_range
            : renderList(data.campaign_specification.company_size_range),
        [TableAccessors.report]: data.lead_data.company_size_range,
        [TableAccessors.matchIO]: {
          value: statuses[MatchIOFields.company_size_range_status],
          name: MatchIOFields.company_size_range_status,
        },
      },
      {
        [TableAccessors.rowName]: t('common.field.industry'),
        [TableAccessors.actualData]:
          typeof data.third_party_data.company_industries === 'string'
            ? data.third_party_data.company_industries
            : renderList(data.third_party_data.company_industries),
        [TableAccessors.campaignSpecification]:
          data.campaign_specification.company_industries,
        [TableAccessors.report]: data.lead_data.company_industries,
        [TableAccessors.matchIO]: {
          value: statuses[MatchIOFields.company_industries_status],
          name: MatchIOFields.company_industries_status,
        },
      },
      {
        [TableAccessors.rowName]: t('common.field.location'),
        [TableAccessors.actualData]:
          typeof data.third_party_data.company_location === 'string'
            ? data.third_party_data.company_location
            : renderList(data.third_party_data.company_location),
        [TableAccessors.campaignSpecification]:
          typeof data.campaign_specification.company_location === 'string'
            ? data.campaign_specification.company_location
            : renderList(data.campaign_specification.company_location),
        [TableAccessors.report]: data.lead_data.company_location,
        [TableAccessors.matchIO]: {
          value: statuses[MatchIOFields.company_location_status],
          name: MatchIOFields.company_location_status,
        },
      },
      {
        [TableAccessors.rowName]: t('common.field.tal'),
        [TableAccessors.actualData]: data.third_party_data.company_tal,
        [TableAccessors.campaignSpecification]:
          data.campaign_specification.company_tal,
        [TableAccessors.report]: data.lead_data.company_tal,
        [TableAccessors.matchIO]: {
          value: statuses[MatchIOFields.company_tal_status],
          name: MatchIOFields.company_tal_status,
        },
      },
      {
        [TableAccessors.rowName]: t('common.field.sal'),
        [TableAccessors.actualData]: data.third_party_data.company_sal,
        [TableAccessors.campaignSpecification]:
          data.campaign_specification.company_sal,
        [TableAccessors.report]: data.lead_data.company_sal,
        [TableAccessors.matchIO]: {
          value: statuses[MatchIOFields.company_sal_status],
          name: MatchIOFields.company_sal_status,
        },
      },
      {
        [TableAccessors.rowName]: t('common.field.revenueActual'),
        [TableAccessors.actualData]:
          data.third_party_data.company_revenue_actual,
        [TableAccessors.campaignSpecification]:
          data.campaign_specification.company_revenue_actual,
        [TableAccessors.report]: data.lead_data.company_revenue_actual,
        [TableAccessors.matchIO]: {
          value: statuses[MatchIOFields.company_revenue_actual_status],
          name: MatchIOFields.company_revenue_actual_status,
        },
      },
      {
        [TableAccessors.rowName]: t('common.field.revenueRange'),
        [TableAccessors.actualData]:
          data.third_party_data.company_revenue_range,
        [TableAccessors.campaignSpecification]:
          typeof data.campaign_specification.company_revenue_range === 'string'
            ? data.campaign_specification.company_revenue_range
            : renderList(data.campaign_specification.company_revenue_range),
        [TableAccessors.report]: data.lead_data.company_revenue_range,
        [TableAccessors.matchIO]: {
          value: statuses[MatchIOFields.company_revenue_range_status],
          name: MatchIOFields.company_revenue_range_status,
        },
      },
      {
        [TableAccessors.rowName]: t('common.field.naicsCode'),
        [TableAccessors.actualData]:
          typeof data.third_party_data.company_naics_codes === 'string'
            ? data.third_party_data.company_naics_codes
            : renderList(data.third_party_data.company_naics_codes),
        [TableAccessors.campaignSpecification]:
          data.campaign_specification.company_naics_codes,
        [TableAccessors.report]: data.lead_data.company_naics_codes,
        [TableAccessors.matchIO]: {
          value: statuses[MatchIOFields.company_naics_status],
          name: MatchIOFields.company_naics_status,
        },
      },
      {
        [TableAccessors.rowName]: t('common.field.sicCode'),
        [TableAccessors.actualData]:
          typeof data.third_party_data.company_sic_codes === 'string'
            ? data.third_party_data.company_sic_codes
            : renderList(data.third_party_data.company_sic_codes),
        [TableAccessors.campaignSpecification]:
          data.campaign_specification.company_sic_codes,
        [TableAccessors.report]: data.lead_data.company_sic_codes,
        [TableAccessors.matchIO]: {
          value: statuses[MatchIOFields.company_sic_status],
          name: MatchIOFields.company_sic_status,
        },
      },
    ];
  }, [statuses]);

  const columnsData = useMemo(
    () => [
      {
        Header: '',
        accessor: TableAccessors.rowName,
        fixed: 'left',
      },
      {
        Header: t('common.field.campaignSpecification'),
        accessor: TableAccessors.campaignSpecification,
      },
      {
        Header: t('common.field.actualData'),
        accessor: TableAccessors.actualData,
      },
      {
        Header: t('common.field.report'),
        accessor: TableAccessors.report,
      },
      {
        Header: t('common.field.matchIO'),
        accessor: TableAccessors.matchIO,
        Cell: ({ value }: { value: { value: MatchIO; name: MatchIOFields } }) =>
          value ? (
            <MatchIOSelect
              value={{
                value: value.value,
                label: t(`common.field.${value.value}`),
              }}
              name={value.name}
              onChangeMatchIOStatus={onChangeStatus}
            />
          ) : (
            <Select
              options={[]}
              styles={authSelectStyles}
              placeholder={t('common.field.not-applicable')}
              components={{
                DropdownIndicator,
              }}
              className={styles.select}
              isDisabled
            />
          ),
      },
    ],
    [statuses]
  );

  return (
    <div className={styles.profileRowWrapper}>
      <Table
        tableOptions={{ columns: columnsData, data: rowsData }}
        wrapperClassName={styles.table}
        isFirstColumnFixed
      />
    </div>
  );
};

export default CompanyCheck;
