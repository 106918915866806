import React from 'react';
import cn from 'classnames';
import Select, { components } from 'react-select';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';

import type {
  FieldErrors,
  FieldValues,
  UseFormRegister,
  UseFormReturn,
} from 'react-hook-form';
import type { DropdownIndicatorProps, GroupBase } from 'react-select';
import { InputData, SelectOption } from 'types/models';

import AddressField from 'components/AuthForm/AddressField';
import PhoneField from 'components/AuthForm/PhoneField';
import Error from 'components/common/Error';
import DatePicker from 'components/common/DatePicker';
import CountryField from 'components/AuthForm/CountryField';
import JobTitleField from 'components/AuthForm/JobTitleField';
import StateProvinceField from 'components/AuthForm/StateProvinceField';
import TimezoneSelectField from 'components/AuthForm/TimezoneField';
import IconSVG from 'components/common/IconSVG';

import { authSelectStyles } from 'utils/selectStyles';

import useTranslateSelectOptions from 'hooks/useTranslateSelectOptions';

import { IconsNames } from 'constants/icons';
import { AuthFormFieldTypes } from 'constants/constants';

import styles from './index.module.scss';

const getNestedErrorValue = (object: FieldErrors, path: string) => {
  const pathArray = path.split('.');
  // @ts-ignore
  return pathArray.reduce((acc, val) => {
    if (acc && acc[val]) {
      return acc[val];
    } else {
      return undefined;
    }
  }, object);
};

export type InputFieldProps<FormValues extends FieldValues> = {
  data: InputData<FormValues>;
  register: UseFormRegister<FormValues>;
  disableChanges?: boolean;
  validationError: FieldErrors;
  formSettings: UseFormReturn<FormValues, any>;
  labelClasses?: string;
};

export const DropdownIndicator = (
  props: DropdownIndicatorProps<SelectOption, boolean, GroupBase<SelectOption>>
) => {
  return (
    <components.DropdownIndicator {...props}>
      <IconSVG name={IconsNames.selectArrows} />
    </components.DropdownIndicator>
  );
};

const InputField = <FormValues extends FieldValues>({
  data,
  register,
  disableChanges,
  validationError,
  formSettings,
  labelClasses,
}: InputFieldProps<FormValues>): JSX.Element => {
  const { t } = useTranslation();
  const {
    type,
    name,
    required,
    label,
    fromMonth,
    toMonth,
    selectOptions,
    disabled,
    placeholder,
  } = data;
  const translateOptions = useTranslateSelectOptions();
  const translatedOptions = translateOptions(selectOptions || []);

  const isNestedField = name.split('.').length > 1;
  const error = isNestedField
    ? getNestedErrorValue(validationError, name)
    : validationError[name];
  const arrayError = Array.isArray(error) && error[0];
  const yupErrorMessage =
    // @ts-ignore
    typeof error !== 'string' && (error?.message || error?.value?.message);
  const translationObjError =
    yupErrorMessage?.key && t(yupErrorMessage?.key, yupErrorMessage.values);
  const translationKeyError = t(yupErrorMessage);
  const errorMessage = arrayError || translationObjError || translationKeyError;
  const isStringLabel = typeof label === 'string';

  const countryValue = formSettings.getValues().company_country_code?.value;
  const isStateVisible =
    name !== 'company_state' ||
    (name === 'company_state' &&
      (countryValue === 'US' || countryValue === 'CA'));

  const renderComponent = () => {
    switch (type) {
      case AuthFormFieldTypes.phone:
        return <PhoneField control={formSettings.control} name={name} />;
      case AuthFormFieldTypes.companyCountry:
        return <CountryField formSettings={formSettings} name={name} />;
      case AuthFormFieldTypes.companyStateProvince:
        return <StateProvinceField formSettings={formSettings} name={name} />;
      case AuthFormFieldTypes.date:
        return (
          <Controller
            name={name}
            control={formSettings.control}
            render={({ field: { value, onChange } }) => (
              <DatePicker
                value={value as string}
                onChange={onChange}
                fromMonth={fromMonth}
                toMonth={toMonth}
                inputClassName={styles.input}
              />
            )}
          />
        );
      case AuthFormFieldTypes.address:
        return (
          <Controller
            name={name}
            control={formSettings.control}
            render={({ field }) => (
              <AddressField field={field} formSettings={formSettings} />
            )}
          />
        );
      case AuthFormFieldTypes.jobTitle:
        return (
          <Controller
            name={name}
            control={formSettings.control}
            render={({ field }) => (
              <JobTitleField field={field} styles={authSelectStyles} />
            )}
          />
        );
      case AuthFormFieldTypes.singleSelect: {
        if (selectOptions) {
          return (
            <Controller
              name={name}
              control={formSettings.control}
              render={({ field: { value, onChange, onBlur } }) => (
                <Select<SelectOption>
                  value={value as SelectOption}
                  onChange={onChange}
                  options={translatedOptions}
                  styles={authSelectStyles}
                  placeholder=""
                  onBlur={onBlur}
                  components={{ DropdownIndicator }}
                />
              )}
            />
          );
        } else return;
      }
      case AuthFormFieldTypes.city:
        return (
          <Controller
            name={name}
            control={formSettings.control}
            render={({ field: { value, onChange } }) => (
              <input
                {...register(name, { required })}
                type={type}
                value={value}
                onChange={onChange}
                disabled={disabled || disableChanges}
                id={name}
                className={styles.input}
                autoComplete={`${type}-form`}
                role="textbox"
              />
            )}
          />
        );
      case AuthFormFieldTypes.timezone:
        return <TimezoneSelectField formSettings={formSettings} name={name} />;
      case AuthFormFieldTypes.text:
      default: {
        return (
          <input
            type={type}
            placeholder={placeholder}
            {...register(name, { required })}
            disabled={disabled || disableChanges}
            id={name}
            className={styles.input}
            autoComplete={`${type}-form`}
            min={0}
            role="textbox"
          />
        );
      }
    }
  };

  return (
    <>
      {isStateVisible && (
        <label
          htmlFor={name}
          className={cn(styles.label, labelClasses, {
            [styles.label__checkbox]: type === 'checkbox',
          })}
        >
          {label && (
            <span className={styles.label__text}>
              {isStringLabel ? t(`common.field.${label}`) : label}
            </span>
          )}
          <Error message={errorMessage} className={styles.error} />
          {renderComponent()}
        </label>
      )}
    </>
  );
};

export default InputField;
