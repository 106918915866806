import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';
import { useMutation, useQuery } from 'react-query';
import { AxiosError } from 'axios';

import {
  CallNumberState,
  ConclusionStatus,
  MatchIOFields,
  MatchIOStatuses,
  QCofTheLeadData,
  Reasons,
  ScriptQuestionAboutInterests,
} from 'types/models';

import Button from 'components/common/Button';
import Loader from 'components/common/LoaderScreen/Loader';
import Panel from 'components/common/Panel';
import CompanyInfo from 'components/QCSessionContent/CompanyInfo';
import PersonInfo from 'components/QCSessionContent/PersonInfo';
import CompanyCheck from 'components/QCSessionContent/CompanyCheck';
import PersonCheck from 'components/QCSessionContent/PersonCheck';
import EmptyContent from 'components/common/EmptyContent';
import ModalWindow from 'components/common/ModalWindow';
import ContactVerification from 'components/QCSessionContent/ContactVerification';
import CallModal from 'components/QCSessionContent/CallModal';
import CallScript from 'components/QCSessionContent/CallScript';
import CallStatusModal from 'components/QCSessionContent/CallStatusModal';

import PersonImg from 'assets/images/qcSession/user.svg';
import BuildingImg from 'assets/images/qcSession/buildings.svg';

import { PrivatePaths } from 'constants/routes';
import { IconsNames } from 'constants/icons';
import { MatchIO } from 'constants/constants';
import { API_ENDPOINTS } from 'constants/endpoints';

import useModal from 'contexts/ModalContext';
import useAuth from 'contexts/AuthContext';

import styles from './index.module.scss';

const defaultMatchIOStatuses = {
  company_industries_status: MatchIO.invalid,
  company_location_status: MatchIO.invalid,
  company_naics_status: MatchIO.invalid,
  company_name_status: MatchIO.invalid,
  company_revenue_actual_status: MatchIO.invalid,
  company_revenue_range_status: MatchIO.invalid,
  company_sal_status: MatchIO.invalid,
  company_sic_status: MatchIO.invalid,
  company_size_actual_status: MatchIO.invalid,
  company_size_range_status: MatchIO.invalid,
  company_tal_status: MatchIO.invalid,
  profile_dual_employment_status: MatchIO.invalid,
  profile_location_status: MatchIO.invalid,
  profile_num_month_in_current_company_status: MatchIO.invalid,
  profile_qualified_contacts_per_company_status: MatchIO.invalid,
  profile_workplace_company_name_status: MatchIO.invalid,
  profile_workplace_job_title_status: MatchIO.invalid,
  contact_personal_number_status: null,
  contact_headquarter_number_status: null,
  contact_script_status: null,
  contact_personal_number_reasons: null,
  contact_headquarter_number_reasons: null,
};

const QCSessionContent = () => {
  const [matchIOStatuses, setMatchIOStatuses] = useState<MatchIOStatuses>(
    defaultMatchIOStatuses
  );
  const [statusOfTheLead, setStatusOfTheLead] = useState<ConclusionStatus>(
    ConclusionStatus.pending
  );
  const [scriptInterests, setScriptInterests] = useState<
    ScriptQuestionAboutInterests[]
  >([]);
  const [isAcceptReceivingInfo, setIsAcceptReceivingInfo] = useState<
    boolean | null
  >(null);

  const [isCallModalOpen, setIsCallModalOpen] = useState<boolean>(false);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const { axios, user } = useAuth();
  const { openModal, closeModal } = useModal();

  const { data, isLoading } = useQuery(
    ['startQC'],
    async () => axios.get<QCofTheLeadData>(API_ENDPOINTS.startQC),
    {
      onError: (err: AxiosError) => {
        if (err.response?.status !== 404) {
          openModal({
            Content: (
              <ModalWindow
                title={t('common.error.something-went-wrong')}
                errorMessage={err.message}
              />
            ),
          });
        }
      },
      onSuccess: response => {
        if (response && response.data.matchio) {
          setMatchIOStatuses({
            ...matchIOStatuses,
            [MatchIOFields.company_industries_status]:
              response.data.matchio[MatchIOFields.company_industries_status],
            [MatchIOFields.company_name_status]:
              response.data.matchio[MatchIOFields.company_name_status],
            [MatchIOFields.company_location_status]:
              response.data.matchio[MatchIOFields.company_location_status],
            [MatchIOFields.company_naics_status]:
              response.data.matchio[MatchIOFields.company_naics_status],
            [MatchIOFields.company_revenue_actual_status]:
              response.data.matchio[
                MatchIOFields.company_revenue_actual_status
              ],
            [MatchIOFields.company_revenue_range_status]:
              response.data.matchio[MatchIOFields.company_revenue_range_status],
            [MatchIOFields.company_sal_status]:
              response.data.matchio[MatchIOFields.company_sal_status],
            [MatchIOFields.company_sic_status]:
              response.data.matchio[MatchIOFields.company_sic_status],
            [MatchIOFields.company_size_actual_status]:
              response.data.matchio[MatchIOFields.company_size_actual_status],
            [MatchIOFields.company_size_range_status]:
              response.data.matchio[MatchIOFields.company_size_range_status],
            [MatchIOFields.company_tal_status]:
              response.data.matchio[MatchIOFields.company_tal_status],
            [MatchIOFields.profile_dual_employment_status]:
              response.data.matchio[
                MatchIOFields.profile_dual_employment_status
              ],
            [MatchIOFields.profile_location_status]:
              response.data.matchio[MatchIOFields.profile_location_status],
            [MatchIOFields.profile_num_month_in_current_company_status]:
              response.data.matchio[
                MatchIOFields.profile_num_month_in_current_company_status
              ],
            [MatchIOFields.profile_qualified_contacts_per_company_status]:
              response.data.matchio[
                MatchIOFields.profile_qualified_contacts_per_company_status
              ],
            [MatchIOFields.profile_workplace_company_name_status]:
              response.data.matchio[
                MatchIOFields.profile_workplace_company_name_status
              ],
            [MatchIOFields.profile_workplace_job_title_status]:
              response.data.matchio[
                MatchIOFields.profile_workplace_job_title_status
              ],
          });
        }
      },
    }
  );

  const { mutate, isLoading: isSubmitLoading } = useMutation<
    MatchIOStatuses,
    AxiosError,
    MatchIOStatuses
  >(
    async statusesData => {
      try {
        const { data: resultData } = await axios.post<MatchIOStatuses>(
          `${process.env.REACT_APP_BACKEND_URL}${API_ENDPOINTS.submitQC}${data?.data.matchio.conclusion_id}/`,
          statusesData
        );

        return resultData;
      } catch (err) {
        throw err;
      }
    },
    {
      onSuccess: () => {
        navigate(`/${PrivatePaths.DASHBOARD}`);
      },
      onError: (err: AxiosError) => {
        openModal({
          Content: (
            <ModalWindow
              title={t('common.error.something-went-wrong')}
              errorMessage={err.message}
            />
          ),
        });
      },
    }
  );

  const qcLead = data?.data;

  const isSubmitDisabled =
    matchIOStatuses.contact_headquarter_number_status === null ||
    matchIOStatuses.contact_personal_number_status === null ||
    matchIOStatuses.contact_script_status === null;

  const callScript = qcLead ? (
    <CallScript
      data={qcLead}
      scriptInterests={scriptInterests}
      setScriptInterests={setScriptInterests}
      isAcceptReceivingInfo={isAcceptReceivingInfo}
      setIsAcceptReceivingInfo={setIsAcceptReceivingInfo}
      userName={user ? `${user.first_name} ${user.last_name}` : '-'}
    />
  ) : (
    <div className={styles.loaderWrapper}>
      <Loader />
    </div>
  );

  const handleOpenScript = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    openModal({
      Content: (
        <ModalWindow title=" " className={styles.callGeneralModal}>
          {callScript}
        </ModalWindow>
      ),
    });
  };

  const calculateGeneralStatus = (values: MatchIOStatuses) => {
    const {
      // eslint-disable-next-line
      contact_personal_number_status,
      // eslint-disable-next-line
      contact_headquarter_number_status,
      // eslint-disable-next-line
      contact_headquarter_number_reasons,
      // eslint-disable-next-line
      contact_personal_number_reasons,
      // eslint-disable-next-line
      contact_script_status,
      ...tableStatuses
    } = values;

    const isInvalid = Object.values(tableStatuses).some(
      item => item === MatchIO.invalid
    );
    const isUnableToVerify = Object.values(tableStatuses).some(
      item => item === MatchIO.unable_to_verify
    );
    if (isInvalid) {
      setStatusOfTheLead(ConclusionStatus.disqualified);
    } else if (isUnableToVerify) {
      setStatusOfTheLead(ConclusionStatus.unable_to_verify);
    } else {
      if (
        contact_personal_number_status === MatchIO.valid ||
        contact_headquarter_number_status === MatchIO.valid
      ) {
        setStatusOfTheLead(ConclusionStatus.qualified);
      } else {
        if (
          contact_personal_number_status === MatchIO.invalid ||
          contact_headquarter_number_status === MatchIO.invalid
        ) {
          setStatusOfTheLead(ConclusionStatus.disqualified);
        } else {
          setStatusOfTheLead(ConclusionStatus.unable_to_verify);
        }
      }
    }
  };

  const handleChangeMatchIOStatus = (name: string, value: MatchIO) => {
    const values = { ...matchIOStatuses, [name]: value };
    setMatchIOStatuses(values);
    if (
      matchIOStatuses.contact_personal_number_status ||
      matchIOStatuses.contact_headquarter_number_status
    ) {
      calculateGeneralStatus(values);
    }
  };

  const handleChangeStatusOfCall = (
    phoneType: CallNumberState,
    statusValue: MatchIO,
    reasonsValue: Reasons
  ) => {
    const values = {
      ...matchIOStatuses,
      [phoneType === CallNumberState.personal_number
        ? MatchIOFields.contact_personal_number_status
        : MatchIOFields.contact_headquarter_number_status]: statusValue,
      [phoneType === CallNumberState.personal_number
        ? MatchIOFields.contact_personal_number_reasons
        : MatchIOFields.contact_headquarter_number_reasons]: reasonsValue,
    };
    setMatchIOStatuses(values);
    calculateGeneralStatus(values);
    closeModal();
  };

  const handleCloseCallModal = (phoneType: CallNumberState) => {
    setIsCallModalOpen(false);
    closeModal();
    openModal({
      Content: (
        <CallStatusModal
          onDoneClick={(statusValue, reasons) =>
            handleChangeStatusOfCall(phoneType, statusValue, reasons)
          }
        />
      ),
    });
  };

  const handleCallClick = (value: CallNumberState) => {
    setIsCallModalOpen(true);
    openModal({
      Content: (
        <CallModal
          title={t(`qc_session.${value}`)}
          icon={
            value === CallNumberState.personal_number ? PersonImg : BuildingImg
          }
          onClose={() => handleCloseCallModal(value)}
        >
          {callScript}
        </CallModal>
      ),
    });
  };

  const renderStatusIcon = () => {
    switch (statusOfTheLead) {
      case ConclusionStatus.qualified:
        return IconsNames.like;
      case ConclusionStatus.disqualified:
        return IconsNames.dislike;
      case ConclusionStatus.unable_to_verify:
        return IconsNames.questionCircle;
      case ConclusionStatus.pending:
      default:
        return IconsNames.timer;
    }
  };

  return (
    <main className={styles.mainContainer}>
      <header className={styles.header}>
        <h5>{t('qc_session.heading')}</h5>
        <Button
          className={styles.finishButton}
          iconProps={{
            name: IconsNames.flag,
            isRightPosition: false,
          }}
          white
          onClick={() => navigate(`/${PrivatePaths.DASHBOARD}`)}
        >
          {t('common.button.finish-session')}
        </Button>
      </header>
      {!qcLead ? (
        <div className={styles.emptyScreenWrapper}>
          {isLoading ? (
            <div className={styles.loaderWrapper}>
              <Loader />
            </div>
          ) : (
            <EmptyContent title={t('qc_session.no-sessions')} />
          )}
        </div>
      ) : (
        <section className={styles.contentWrapper}>
          {isSubmitLoading && (
            <div className={styles.loaderWrapper}>
              <Loader />
            </div>
          )}
          {!isCallModalOpen && (
            <Button
              className={styles.submitButton}
              iconProps={{
                name: IconsNames.check,
                isRightPosition: false,
              }}
              onClick={() => {
                mutate(matchIOStatuses);
              }}
              disabled={isSubmitDisabled}
            >
              {t('common.button.submit')}
            </Button>
          )}
          <Panel
            title={t('common.field.lead-status')}
            subtitle={t(`common.field.${statusOfTheLead}`)}
            iconStyles={cn({
              [styles.status__pending]:
                statusOfTheLead === ConclusionStatus.pending,
              [styles.status__qualified]:
                statusOfTheLead === ConclusionStatus.qualified,
              [styles.status__disqualified]:
                statusOfTheLead === ConclusionStatus.disqualified,
            })}
            icon={renderStatusIcon()}
          />
          <Panel
            title={t('common.field.company')}
            subtitle={t('common.field.profile')}
            icon={IconsNames.companyProfile}
          >
            <CompanyInfo data={qcLead.company_profile} />
          </Panel>
          <Panel
            title={t('common.field.company')}
            subtitle={t('common.field.check')}
            icon={IconsNames.companyProfileCheck}
          >
            <CompanyCheck
              data={qcLead}
              onChangeStatus={handleChangeMatchIOStatus}
              statuses={matchIOStatuses}
            />
          </Panel>
          <Panel
            title={t('common.field.person')}
            subtitle={t('common.field.profile')}
            icon={IconsNames.person}
          >
            <PersonInfo data={qcLead.person_profile} />
          </Panel>
          <Panel
            title={t('common.field.person')}
            subtitle={t('common.field.check')}
            icon={IconsNames.personCheck}
          >
            <PersonCheck
              data={qcLead}
              onChangeStatus={handleChangeMatchIOStatus}
              statuses={matchIOStatuses}
            />
          </Panel>
          <Panel
            title={t('common.field.contact')}
            subtitle={t('common.field.check')}
            icon={IconsNames.smartphoneCheck}
            rightSideComponent={
              <Button
                onClick={handleOpenScript}
                white
                iconProps={{ name: IconsNames.list, isRightPosition: false }}
              >
                {t('qc_session.open-script')}
              </Button>
            }
          >
            <ContactVerification
              statuses={matchIOStatuses}
              onChangeStatus={handleChangeMatchIOStatus}
              onCallClick={handleCallClick}
            />
          </Panel>
        </section>
      )}
    </main>
  );
};

export default QCSessionContent;
