export enum LocalStorageKeys {
  ACCESS_TOKEN = 'accessToken',
  REFRESH_TOKEN = 'refreshToken',
  IS_PROFILE_COMPLETED = 'isProfileFilled',
}

export enum AuthFormFieldTypes {
  phone = 'phone',
  date = 'date',
  address = 'address',
  companyCountry = 'company-country',
  companyStateProvince = 'company-state-province',
  jobTitle = 'job-title',
  singleSelect = 'single-select',
  text = 'text',
  checkbox = 'checkbox',
  city = 'city',
  timezone = 'timezone',
}

export enum UserRoles {
  qc_agent = 'qc-agent',
}

export enum MatchIO {
  valid = 'valid',
  invalid = 'invalid',
  unable_to_verify = 'unable_to_verify',
}

export const NoValueFromBE = '-';
