import React from 'react';

import styles from './index.module.scss';

type Props = {
  title: string;
  children: React.ReactNode;
};

const InfoBlock = ({ title, children }: Props): JSX.Element => {
  return (
    <div className={styles.infoBlockWrapper}>
      <p className={styles.title}>{title}</p>
      {children}
    </div>
  );
};

export default InfoBlock;
