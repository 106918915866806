import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CompanyProfile } from 'types/models';

import InfoBlock from 'components/QCSessionContent/InfoBlock';
import IconSVG from 'components/common/IconSVG';
import ButtonMoreWithPopup from 'components/common/ButtonMoreWithPopup';

import { IconsNames } from 'constants/icons';
import { NoValueFromBE } from 'constants/constants';

import styles from './index.module.scss';

type Props = {
  data: CompanyProfile;
};

export const OverviewMax = 400;
export const SpecialitiesMax = 5;

const CompanyInfo = ({ data }: Props): JSX.Element => {
  const [isShowMoreOverview, setIsShowMoreOverview] = useState<boolean>(false);
  const { t } = useTranslation();

  const isLongOverview = data.overview.length > OverviewMax;

  const overview =
    isLongOverview && !isShowMoreOverview
      ? `${data.overview.substring(0, OverviewMax)}...`
      : data.overview;

  const specialitiesList = data.specialities;

  const specialities = useMemo(
    () =>
      specialitiesList.length > 0
        ? (specialitiesList.length > SpecialitiesMax
            ? specialitiesList.slice(0, SpecialitiesMax)
            : specialitiesList
          ).map(item => <p key={item}>{item}</p>)
        : '-',
    [specialitiesList]
  );

  const isLongSpecialities = specialitiesList.length > SpecialitiesMax;

  const handleShowMoreOverviewClick = () => {
    setIsShowMoreOverview(!isShowMoreOverview);
  };

  return (
    <div className={styles.companyRowWrapper}>
      <div className={styles.overviewWrapper}>
        <InfoBlock title={t('common.field.overview')}>
          <p>
            {overview}{' '}
            {isLongOverview && (
              <span
                className={styles.showMore}
                onClick={handleShowMoreOverviewClick}
              >
                {isShowMoreOverview
                  ? t('common.field.showLess')
                  : t('common.field.showMore')}
              </span>
            )}
          </p>
        </InfoBlock>
      </div>
      <div className={styles.columnWrapper}>
        <InfoBlock title={t('common.field.description')}>
          <p>{data.description}</p>
        </InfoBlock>
        <InfoBlock title={t('common.field.followers')}>
          <p>{data.followers}</p>
        </InfoBlock>
      </div>
      <div className={styles.columnWrapper}>
        <InfoBlock title={t('common.field.headquarters')}>
          {data.headquarter}
        </InfoBlock>
        <InfoBlock title={t('common.field.specialities')}>
          <div>
            {specialities}
            {isLongSpecialities && (
              <ButtonMoreWithPopup
                popupContentList={specialitiesList.slice(
                  SpecialitiesMax,
                  specialitiesList.length
                )}
                buttonText={`+${specialitiesList.length - SpecialitiesMax}`}
              />
            )}
          </div>
        </InfoBlock>
      </div>
      <div className={styles.columnWrapper}>
        <div className={styles.linkRow}>
          {data.website_url !== NoValueFromBE && (
            <a
              href={data.website_url}
              className={styles.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconSVG name={IconsNames.globe} />
            </a>
          )}
          {data.linkedin_url !== NoValueFromBE && (
            <a
              href={data.linkedin_url}
              className={styles.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconSVG name={IconsNames.linkedin} />
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default CompanyInfo;
