import React from 'react';
import cn from 'classnames';
import { usePopperTooltip } from 'react-popper-tooltip';

import type { ReactI18NextChild } from 'react-i18next';

import Button from 'components/common/Button';

import styles from './index.module.scss';

type Props = {
  popupContentList: string[] | ReactI18NextChild[];
  buttonText: string | React.ReactNode;
};

const ButtonMoreWithPopup = ({
  buttonText,
  popupContentList,
}: Props): JSX.Element => {
  const { getTooltipProps, setTooltipRef, setTriggerRef, visible } =
    usePopperTooltip({ placement: 'right' });

  return (
    <>
      <Button lightBlue ref={setTriggerRef}>
        {buttonText}
      </Button>

      {visible && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({
            className: cn('tooltip-container', styles.tooltip),
          })}
        >
          {popupContentList.map((item, index) => (
            <p key={`${item}_${index}`}>{item}</p>
          ))}
        </div>
      )}
    </>
  );
};

export default ButtonMoreWithPopup;
