import React from 'react';
import { Navigate } from 'react-router-dom';
import { useLocalStorage } from 'react-use';

import AuthLayout from 'layouts/AuthLayout';

import { PrivatePaths, PublicPaths } from 'constants/routes';
import { LocalStorageKeys } from 'constants/constants';

const AuthorizedAndNotFilledRoute = (): JSX.Element => {
  const [token] = useLocalStorage<string>(LocalStorageKeys.ACCESS_TOKEN);
  const [isProfileFilled] = useLocalStorage<boolean>(
    LocalStorageKeys.IS_PROFILE_COMPLETED
  );

  return token ? (
    isProfileFilled ? (
      <Navigate to={`/${PrivatePaths.DASHBOARD}`} />
    ) : (
      <AuthLayout />
    )
  ) : (
    <Navigate to={`/${PublicPaths.LOG_IN}`} />
  );
};

export default AuthorizedAndNotFilledRoute;
