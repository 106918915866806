import React from 'react';
import cn from 'classnames';

import { IconsNames } from 'constants/icons';

import IconSVG from 'components/common/IconSVG';

import styles from '../index.module.scss';

type Props = {
  className?: string;
};

const Loader = ({ className }: Props): JSX.Element => {
  return (
    <IconSVG name={IconsNames.loading} className={cn(styles.spin, className)} />
  );
};

export default Loader;
