import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from 'react-use';
import { useTranslation } from 'react-i18next';
import { useGoogleLogin } from '@react-oauth/google';
import axios, { AxiosResponse } from 'axios';
import { useMutation } from 'react-query';

import { PrivatePaths } from 'constants/routes';
import { LocalStorageKeys } from 'constants/constants';
import { IconsNames } from 'constants/icons';
import { API_ENDPOINTS } from 'constants/endpoints';

import Button from 'components/common/Button';
import IconSVG from 'components/common/IconSVG';

import useAuth from 'contexts/AuthContext';

import styles from './index.module.scss';

type AuthDataToSend = {
  authorization_token: string;
};

type AuthDataToGet = {
  access_token: string;
  refresh_token: string;
};

const LogInContent = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { axios: axiosInstance, setState } = useAuth();
  const [error, setError] = useState<string>();
  const [, setAccessTokenValue, removeAccessToken] = useLocalStorage(
    LocalStorageKeys.ACCESS_TOKEN
  );
  const [, setRefreshTokenValue, removeRefreshToken] = useLocalStorage(
    LocalStorageKeys.REFRESH_TOKEN
  );

  const [, setIsProfileFilled] = useLocalStorage(
    LocalStorageKeys.IS_PROFILE_COMPLETED
  );

  const { mutate, isLoading } = useMutation<
    AuthDataToGet,
    AxiosResponse,
    AuthDataToSend
  >(
    async (accessTokenData: AuthDataToSend) => {
      try {
        setError('');
        const { data: authData } = await axios.post<AuthDataToGet>(
          `${process.env.REACT_APP_BACKEND_URL}${API_ENDPOINTS.googleAuth}`,
          accessTokenData
        );
        setAccessTokenValue(authData.access_token);
        setRefreshTokenValue(authData.refresh_token);
        return authData;
      } catch (err) {
        setError(t('common.error.user-doesnt-exist'));
        throw err;
      }
    },
    {
      onSuccess: async () => {
        try {
          const { data } = await axiosInstance.get(API_ENDPOINTS.profile);
          setState({ user: data });
          setIsProfileFilled(data.is_profile_complete);
          navigate(
            `/${
              data?.is_profile_complete
                ? PrivatePaths.DASHBOARD
                : PrivatePaths.SIGN_UP
            }`
          );
        } catch (e) {
          removeAccessToken();
          removeRefreshToken();
          setError(t('common.error.something-went-wrong'));
        }
      },
    }
  );

  const login = useGoogleLogin({
    onSuccess: tokenResponse => {
      mutate({ authorization_token: tokenResponse.access_token });
    },
    scope: 'profile email',
  });

  return (
    <div className={styles.wrapper}>
      <h3 className={styles.label}>{t('auth.welcome')}</h3>
      <div className={styles.buttonWrapper}>
        <Button
          onClick={() => {
            login();
          }}
          iconProps={{
            name: IconsNames.google,
            isRightPosition: false,
          }}
          disabled={isLoading}
          className={styles.button}
          white
        >
          {t('auth.sign-in-google-button')}
        </Button>
        {error && (
          <div className={styles.errorText}>
            <IconSVG name={IconsNames.info} className={styles.errorIcon} />
            <span>{error}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default LogInContent;
