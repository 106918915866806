import React from 'react';

import PageContainer from 'components/common/PageContainer';
import ProfileHeader from 'components/ProfileContent/ProfileHeader';
import ProfileTabs from 'components/ProfileContent/ProfileTabs';

const ProfileContent = () => {
  return (
    <PageContainer isWhiteBackground>
      <ProfileHeader />
      <ProfileTabs />
    </PageContainer>
  );
};

export default ProfileContent;
