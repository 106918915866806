import React from 'react';
import { useTranslation } from 'react-i18next';

import IconSVG from 'components/common/IconSVG';

import { UserRoles } from 'constants/constants';
import { IconsNames } from 'constants/icons';

import useAuth from 'contexts/AuthContext';

import styles from './index.module.scss';

const ProfileHeader = () => {
  const { user } = useAuth();
  const { t } = useTranslation();

  return (
    <div className={styles.headerWrapper}>
      <h3>{t('profile.heading')}</h3>
      <div className={styles.avatarWrapper}>
        <IconSVG name={IconsNames.avatar} className={styles.image} />
        <div className={styles.userNameWrapper}>
          <h3>{`${user?.first_name[0] || ''}. ${user?.last_name || ''}`}</h3>
          <span className={styles.role}>
            {t(
              `common.field.${UserRoles[user?.role as keyof typeof UserRoles]}`
            )}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ProfileHeader;
