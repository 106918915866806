import React from 'react';
import cn from 'classnames';

import PageTitle from 'components/common/PageContainer/PageTitle';
import Menu from 'components/common/PageContainer/Menu';

import styles from './index.module.scss';

type Props = {
  title?: string;
  rightComponent?: React.ReactNode | null;
  isWhiteBackground?: boolean;
  children: React.ReactNode;
};

const PageContainer = ({
  children,
  title,
  rightComponent,
  isWhiteBackground,
}: Props): JSX.Element => {
  return (
    <>
      <Menu />
      <div className={styles.container}>
        <main
          className={cn(styles.main, { [styles.white]: isWhiteBackground })}
        >
          {!!title?.length && (
            <PageTitle title={title} rightComponent={rightComponent} />
          )}
          {children}
        </main>
      </div>
    </>
  );
};

export default PageContainer;
