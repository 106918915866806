import { useTranslation } from 'react-i18next';

const useTranslateSelectOptions = () => {
  const { t } = useTranslation();

  return <T extends string>(list: T[], keyPrefix?: string) => {
    const isPrefix = keyPrefix || keyPrefix === '';
    return list.map(option => ({
      value: option,
      label: t(`${isPrefix ? keyPrefix : 'common.field.'}${option}`),
    }));
  };
};

export default useTranslateSelectOptions;
