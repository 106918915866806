import React from 'react';
import { Navigate } from 'react-router-dom';
import { useLocalStorage } from 'react-use';

import AuthLayout from 'layouts/AuthLayout';

import { PrivatePaths } from 'constants/routes';
import { LocalStorageKeys } from 'constants/constants';

const PublicRoute = () => {
  const [token] = useLocalStorage<string>(LocalStorageKeys.ACCESS_TOKEN);

  return !token ? (
    <AuthLayout />
  ) : (
    <Navigate to={`/${PrivatePaths.DASHBOARD}`} />
  );
};

export default PublicRoute;
