import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import {
  AcceptedAnswers,
  QCofTheLeadData,
  ScriptQuestionAboutInterests,
} from 'types/models';

import IconSVG from 'components/common/IconSVG';

import { IconsNames } from 'constants/icons';

import styles from './index.module.scss';

type Props = {
  data: QCofTheLeadData;
  scriptInterests: ScriptQuestionAboutInterests[];
  setScriptInterests: (value: ScriptQuestionAboutInterests[]) => void;
  isAcceptReceivingInfo: boolean | null;
  setIsAcceptReceivingInfo: (value: boolean) => void;
  userName: string;
};

const CallScript = ({
  data,
  scriptInterests,
  setScriptInterests,
  isAcceptReceivingInfo,
  setIsAcceptReceivingInfo,
  userName,
}: Props): JSX.Element => {
  const [checkboxValues, changeCheckboxValues] =
    useState<ScriptQuestionAboutInterests[]>(scriptInterests);
  const [isAccept, setIsAccept] = useState<boolean | null>(
    isAcceptReceivingInfo
  );
  const { t } = useTranslation();

  useEffect(() => {
    if (scriptInterests) {
      changeCheckboxValues(scriptInterests);
    }
  }, [scriptInterests]);

  const handleChangeInterests = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.checked
      ? [...checkboxValues, e.target.name as ScriptQuestionAboutInterests]
      : [
          ...checkboxValues.filter(
            item => item !== (e.target.name as ScriptQuestionAboutInterests)
          ),
        ];
    setScriptInterests(value);
    changeCheckboxValues(value);
  };

  const handleChangeAcceptedAnswer = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.id === AcceptedAnswers.yes;
    setIsAcceptReceivingInfo(value);
    setIsAccept(value);
  };

  return (
    <div className={styles.scriptWrapper}>
      <header className={styles.header}>
        <IconSVG name={IconsNames.list} />
        <h5>{t('qc_session.call-script')}</h5>
      </header>
      <section className={styles.section}>
        <header className={styles.titleWrapper}>
          <IconSVG name={IconsNames.hello} />
          <span>{t('qc_session.opening')}</span>
        </header>
        <p className={styles.infoLine}>
          {t('qc_session.hi-looking-for', {
            name: `${data.person_profile.first_name} ${data.person_profile.last_name}`,
          })}
        </p>
        <p className={styles.infoLine}>
          {t('qc_session.hi-introduction', {
            firstName: data.person_profile.first_name,
            callerName: userName,
          })}
        </p>
        <p className={styles.infoLine}>{t('qc_session.how-are-you')}</p>
      </section>
      <section className={styles.section}>
        <header className={styles.titleWrapper}>
          <IconSVG name={IconsNames.alignedList} />
          <span>{t('qc_session.introduction')}</span>
        </header>
        <p className={styles.infoLine}>
          {t('qc_session.call-to-understand-what-content')}
        </p>
        {Object.values(ScriptQuestionAboutInterests).map(item => {
          return (
            <div className={styles.checkboxWrapper} key={item}>
              <input
                type={'checkbox'}
                checked={checkboxValues.includes(item)}
                className={styles.checkbox}
                onChange={handleChangeInterests}
                id={item}
                name={item}
              />
              <label htmlFor={item}>{t(`qc_session.${item}`)}</label>
            </div>
          );
        })}
      </section>
      <section className={styles.section}>
        <header className={styles.titleWrapper}>
          <IconSVG
            name={IconsNames.questionCircle}
            className={styles.questionIcon}
          />
          <span>{t('qc_session.qualifying-questions')}</span>
        </header>
        <p className={cn(styles.infoLine, styles.question)}>
          {t('qc_session.question-about-last-job', {
            jobTitle:
              data?.third_party_data?.profile_workplaces &&
              data.third_party_data.profile_workplaces.length > 0
                ? data.third_party_data.profile_workplaces[0]?.title
                : '-',
            companyName:
              data?.third_party_data?.profile_workplaces &&
              data.third_party_data.profile_workplaces.length > 0
                ? data.third_party_data.profile_workplaces[0]?.company_name
                : '-',
          })}
        </p>
        <p className={cn(styles.infoLine, styles.question)}>
          {t('qc_session.question-about-email', {
            email: data.extra_data.email,
          })}
        </p>
        <p className={cn(styles.infoLine, styles.question)}>
          {t('qc_session.question-about-future-communication')}
        </p>
        <div className={styles.radioButtonWrapper}>
          <input
            type={'radio'}
            checked={isAccept === true}
            className={styles.radioButton}
            onChange={handleChangeAcceptedAnswer}
            id={AcceptedAnswers.yes}
          />
          <label htmlFor={AcceptedAnswers.yes}>
            {t('qc_session.yes-only-yes-accepted')}
          </label>
        </div>
        <div className={styles.radioButtonWrapper}>
          <input
            type={'radio'}
            checked={isAccept === false}
            className={styles.radioButton}
            onChange={handleChangeAcceptedAnswer}
            id={AcceptedAnswers.no}
          />
          <label htmlFor={AcceptedAnswers.no}>{t('qc_session.no')}</label>
        </div>
      </section>
      <section className={styles.section}>
        <header className={styles.titleWrapper}>
          <IconSVG name={IconsNames.hello} className={styles.byeIcon} />
          <span>{t('qc_session.closing')}</span>
        </header>
        <p className={styles.infoLine}>{t('qc_session.goodbye-text')}</p>
      </section>
    </div>
  );
};

export default CallScript;
