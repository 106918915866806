import React, { useRef } from 'react';
import cn from 'classnames';
import { useClickAway, useLockBodyScroll } from 'react-use';

import Error from 'components/common/Error';
import IconSVG from 'components/common/IconSVG';

import useModal from 'contexts/ModalContext';
import useEscapeEvent from 'hooks/useEscapeEvent';

import { IconsNames } from 'constants/icons';

import styles from './index.module.scss';

type Props = {
  title?: string | React.ReactNode;
  errorMessage?: string;
  successMessage?: string;
  className?: string;
  closeButtonClassName?: string;
  onClose?: () => void;
  isLockBodyScroll?: boolean;
  closeOnClickAway?: boolean;
  children?: React.ReactNode;
};

const ModalWindow = ({
  children,
  title,
  errorMessage,
  successMessage,
  className,
  closeButtonClassName,
  onClose,
  isLockBodyScroll,
  closeOnClickAway = true,
}: Props): JSX.Element => {
  const { closeModal } = useModal();
  const modalRef = useRef(null);
  useLockBodyScroll(isLockBodyScroll);
  const closeCallback = onClose ? onClose : closeModal;
  useEscapeEvent(closeCallback);
  useClickAway(modalRef, () => {
    if (closeOnClickAway) closeCallback();
  });

  return (
    <div className={styles.modal}>
      <div ref={modalRef} className={cn(styles.window, className)}>
        <header className={styles.header}>
          {title && (
            <>
              {typeof title === 'string' ? (
                <h5 className={styles.title}>{title}</h5>
              ) : (
                <div className={styles.title}>{title}</div>
              )}
            </>
          )}
          <button
            type="button"
            onClick={closeCallback}
            className={cn(styles.close, closeButtonClassName)}
          >
            <IconSVG name={IconsNames.close} />
          </button>
        </header>
        <div className={styles.body}>
          {errorMessage && <Error message={errorMessage} />}
          {successMessage && (
            <p className={styles.success}>
              <IconSVG name={IconsNames.check} /> <span>{successMessage}</span>
            </p>
          )}
          {children}
        </div>
      </div>
    </div>
  );
};

export default ModalWindow;
