import React from 'react';
import { Link, Outlet } from 'react-router-dom';

import { PublicPaths } from 'constants/routes';

import authBanner from 'assets/images/auth/banner.png';
import styles from './index.module.scss';

const AuthLayout = () => {
  return (
    <main className={styles.main} data-testid="auth-layout">
      <section>
        <Link to={`/${PublicPaths.INDEX}`} className={styles.link}>
          <div className={styles.logo}>
            <span>TrueQC</span>
          </div>
        </Link>
      </section>
      <section className={styles.banner}>
        <img
          alt="banner"
          src={authBanner}
          aria-hidden
          className={styles.image}
        />
      </section>
      <section className={styles.content}>
        <Outlet />
      </section>
    </main>
  );
};

export default AuthLayout;
