import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useLocalStorage } from 'react-use';

import type { AxiosError } from 'axios';
import { InputData, SelectOption } from 'types/models';
import type { Path } from 'react-hook-form';

import IconSVG from 'components/common/IconSVG';
import AuthForm from 'components/AuthForm';

import { IconsNames } from 'constants/icons';
import { userDepartmentOptions } from 'constants/selectOptions';
import { AuthFormFieldTypes, LocalStorageKeys } from 'constants/constants';
import { API_ENDPOINTS } from 'constants/endpoints';
import { PrivatePaths } from 'constants/routes';

import {
  validationSchemaSignUpCompanyInfo,
  validationSchemaSignUpGeneralInfo,
} from 'utils/validations';

import useAuth, { User } from 'contexts/AuthContext';

import styles from './index.module.scss';

export enum FormType {
  general = 'general',
  company = 'company',
}

interface SignUpGeneralInfo {
  first_name: string;
  last_name: string;
  date_of_birth: string;
  country_code: SelectOption | string;
  email?: string;
  phone_number: string;
  timezone?: SelectOption | string;
}

interface SignUpCompanyInfo {
  job_title: SelectOption | string;
  department: SelectOption | string;
  company_name: string;
  company_city: string;
  company_address: string;
  company_country_code: SelectOption | string;
  company_state?: SelectOption | string;
}

export interface SignUpData extends SignUpGeneralInfo, SignUpCompanyInfo {}

const currentYear = new Date().getFullYear();
const fromMonth = new Date(currentYear - 100, 0);
const toMonth = new Date(currentYear - 18, 0);

type GeneralInfoField = InputData<SignUpGeneralInfo> & {
  name: Path<SignUpGeneralInfo>;
};
type CompanyInfoField = InputData<SignUpCompanyInfo> & {
  name: Path<SignUpCompanyInfo>;
};

export const generalInfoFields: GeneralInfoField[] = [
  {
    name: 'first_name',
    type: AuthFormFieldTypes.text,
    required: true,
    label: 'first-name',
  },
  {
    name: 'last_name',
    type: AuthFormFieldTypes.text,
    required: true,
    label: 'last-name',
  },
  {
    name: 'date_of_birth',
    type: AuthFormFieldTypes.date,
    required: true,
    label: 'date-of-birth',
    fromMonth,
    toMonth,
  },
  {
    name: 'country_code',
    type: AuthFormFieldTypes.companyCountry,
    required: true,
    label: 'country',
  },
  {
    name: 'email',
    type: AuthFormFieldTypes.text,
    required: false,
    label: 'email',
    disabled: true,
  },
  {
    name: 'phone_number',
    type: AuthFormFieldTypes.phone,
    required: true,
    label: 'phone',
  },
];

export const companyInfoFields: CompanyInfoField[] = [
  {
    name: 'company_name',
    type: AuthFormFieldTypes.text,
    required: true,
    label: 'company-name',
  },
  {
    name: 'job_title',
    type: AuthFormFieldTypes.jobTitle,
    required: true,
    label: 'job-title',
  },
  {
    name: 'department',
    type: AuthFormFieldTypes.singleSelect,
    required: true,
    label: 'department',
    selectOptions: userDepartmentOptions,
  },
  {
    name: 'company_address',
    type: AuthFormFieldTypes.address,
    required: true,
    label: 'company-address',
  },
  {
    name: 'company_country_code',
    type: AuthFormFieldTypes.companyCountry,
    required: true,
    label: 'company-country',
  },
  {
    name: 'company_state',
    type: AuthFormFieldTypes.companyStateProvince,
    required: false,
    label: 'company-state',
  },
  {
    name: 'company_city',
    type: AuthFormFieldTypes.city,
    required: true,
    label: 'company-city',
  },
];

const defaultValues = {
  job_title: '',
  department: '',
  company_name: '',
  company_city: '',
  company_address: '',
  company_country_code: '',
  company_state: '',
};

const SignUpContent = () => {
  const { axios, setState, logout, user } = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [activeFormType, setActiveFormType] = useState<FormType>(
    FormType.general
  );
  const [isProfileFilled, setIsProfileFilled] = useLocalStorage(
    LocalStorageKeys.IS_PROFILE_COMPLETED
  );

  const defaultGeneralInfo = {
    first_name: '',
    last_name: '',
    date_of_birth: '',
    email: user?.email || '',
    phone_number: '',
  };

  useEffect(() => {
    if (isProfileFilled) {
      navigate(`/${PrivatePaths.DASHBOARD}`);
    }
  }, []);

  const { mutate, isLoading, error } = useMutation<
    SignUpData,
    AxiosError,
    SignUpData
  >(
    async userData => {
      try {
        const { data } = await axios.patch<SignUpData>(
          `${process.env.REACT_APP_BACKEND_URL}${API_ENDPOINTS.profile}`,
          userData
        );

        return data;
      } catch (err) {
        throw err;
      }
    },
    {
      onSuccess: async responseBody => {
        const userData = responseBody as User;
        setState({ user: userData });
        await setIsProfileFilled(userData.is_profile_complete);
        navigate(`/${PrivatePaths.DASHBOARD}`);
      },
    }
  );

  const isGeneralForm = activeFormType === FormType.general;

  const handleChangeActiveForm = () => {
    if (isGeneralForm) {
      logout();
    } else {
      setActiveFormType(FormType.general);
    }
  };

  const handleSubmitGeneralInfo = () => {
    setActiveFormType(FormType.company);
  };

  const handleSubmitCompanyInfo = (data: SignUpData) => {
    const { department } = data;
    const requestBody = {
      ...data,
      job_title:
        typeof data.job_title !== 'string'
          ? data.job_title.value
          : data.job_title,
      department: typeof department !== 'string' ? department.value : '',
      company_country_code:
        typeof data.company_country_code !== 'string'
          ? data.company_country_code?.value
          : '',
      country_code:
        typeof data.country_code !== 'string' ? data.country_code?.value : '',
      company_state:
        typeof data.company_state !== 'string' ? data.company_state?.value : '',
    };
    if (
      requestBody.company_country_code !== 'US' &&
      requestBody.company_country_code !== 'CA'
    ) {
      delete requestBody.company_state;
    }

    delete requestBody.email;
    mutate(requestBody);
  };

  const termsAndConditionsInfo = (
    <span className={styles.infoText}>
      {t('auth.terms-and-conditions-agreement')}{' '}
      <a href="#" className={styles.termsLink}>
        {t('auth.terms')}
      </a>{' '}
      {t('auth.terms-read-and-acknowledge')}{' '}
      <a href="#" className={styles.termsLink}>
        {t('auth.global-privacy-statement')}
      </a>
      .
    </span>
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.contentWrapper}>
        <h4>{t('auth.sign-up-title')}</h4>
        <div
          className={styles.formTypeSwitcher}
          onClick={handleChangeActiveForm}
        >
          <IconSVG name={IconsNames.arrowLeft} className={styles.arrowIcon} />
          <h3>
            {t(
              activeFormType === FormType.general
                ? 'auth.general-sign-up-form'
                : 'auth.company-sign-up-form'
            )}
          </h3>
        </div>
        {isGeneralForm ? (
          <AuthForm<SignUpGeneralInfo>
            fields={generalInfoFields}
            onSubmit={handleSubmitGeneralInfo}
            defaultValues={defaultGeneralInfo}
            validationSchema={validationSchemaSignUpGeneralInfo}
            loading={false}
            cleanAfterSubmit={false}
            formFooter={termsAndConditionsInfo}
          />
        ) : (
          <AuthForm<SignUpData>
            fields={companyInfoFields}
            onSubmit={handleSubmitCompanyInfo}
            defaultValues={defaultValues}
            validationSchema={validationSchemaSignUpCompanyInfo}
            loading={isLoading}
            submitError={error}
            cleanAfterSubmit
          />
        )}
      </div>
      <div className={styles.stepsWrapper}>
        {[FormType.general, FormType.company].map(item => (
          <div
            className={cn(styles.step, {
              [styles.activeStep]: item === activeFormType,
            })}
            key={item}
          />
        ))}
      </div>
    </div>
  );
};

export default SignUpContent;
