import type { Path } from 'react-hook-form';
import { AuthFormFieldTypes, MatchIO } from 'constants/constants';
import {
  InvalidContactReason,
  UnableToVerifyContactReason,
  ValidContactReason,
} from 'constants/selectOptions';

export type InputData<FormValues> = {
  type: AuthFormFieldTypes;
  name: Path<FormValues>;
  required: boolean;
  placeholder?: string;
  label?: string | JSX.Element;
  toMonth?: Date;
  fromMonth?: Date;
  selectOptions?: string[];
  disabled?: boolean;
};

export type SelectOption = {
  value: string;
  label: string;
};

export type Reasons = {
  primary_reason:
    | ValidContactReason
    | InvalidContactReason
    | UnableToVerifyContactReason;
  secondary_reason:
    | ValidContactReason
    | InvalidContactReason
    | UnableToVerifyContactReason
    | null;
};

export type CompanyProfile = {
  overview: string;
  description: string;
  followers: string;
  headquarter: string;
  specialities: string[];
  website_url: string;
  linkedin_url: string;
};

export type PersonProfile = {
  first_name: string;
  last_name: string;
  connections_count: string;
  about: string;
};

export type ProfileWorkplace = {
  title: string;
  company_name: string;
  date: string;
};

export interface CompanyPersonCheckData {
  company_link: string;
  company_name: string;
  company_size_actual: string;
  company_size_range: string;
  company_industries: string;
  company_location: string;
  company_tal: string;
  company_sal: string;
  company_revenue_actual: string;
  company_revenue_range: string;
  company_naics_codes: string;
  company_sic_codes: string;
  profile_link: string;
  profile_first_name: string;
  profile_last_name: string;
  profile_location: string;
  profile_education: string;
  profile_dual_employment: string;
  profile_num_month_in_current_company: string;
  profile_qualified_contacts_per_company: string;
  profile_workplaces: ProfileWorkplace[];
}

export interface CompaignSpecificationData
  extends Omit<
    CompanyPersonCheckData,
    | 'company_link'
    | 'company_name'
    | 'company_size_range'
    | 'company_location'
    | 'company_revenue_range'
    | 'profile_workplaces'
  > {
  company_link: string[] | string;
  company_name: string[] | string;
  company_size_range: string[] | string;
  company_location: string[] | string;
  company_revenue_range: string[] | string;
  profile_workplaces: {
    title: string[] | string;
    company_name: string[] | string;
    date: string;
  }[];
}

export interface ThirdPartyData
  extends Omit<
    CompanyPersonCheckData,
    | 'company_industries'
    | 'company_location'
    | 'company_naics_codes'
    | 'company_sic_codes'
  > {
  company_industries: string[] | string;
  company_location: string[] | string;
  company_naics_codes: string[] | string;
  company_sic_codes: string[] | string;
}

export enum MatchIOFields {
  company_industries_status = 'company_industries_status',
  company_location_status = 'company_location_status',
  company_naics_status = 'company_naics_status',
  company_name_status = 'company_name_status',
  company_revenue_actual_status = 'company_revenue_actual_status',
  company_revenue_range_status = 'company_revenue_range_status',
  company_sal_status = 'company_sal_status',
  company_sic_status = 'company_sic_status',
  company_size_actual_status = 'company_size_actual_status',
  company_size_range_status = 'company_size_range_status',
  company_tal_status = 'company_tal_status',
  profile_dual_employment_status = 'profile_dual_employment_status',
  profile_location_status = 'profile_location_status',
  profile_num_month_in_current_company_status = 'profile_num_month_in_current_company_status',
  profile_qualified_contacts_per_company_status = 'profile_qualified_contacts_per_company_status',
  profile_workplace_company_name_status = 'profile_workplace_company_name_status',
  profile_workplace_job_title_status = 'profile_workplace_job_title_status',
  contact_personal_number_status = 'contact_personal_number_status',
  contact_headquarter_number_status = 'contact_headquarter_number_status',
  contact_script_status = 'contact_script_status',
  contact_headquarter_number_reasons = 'contact_headquarter_number_reasons',
  contact_personal_number_reasons = 'contact_personal_number_reasons',
  status = 'status',
}

export interface MatchIOStatuses {
  company_name_status: MatchIO;
  company_industries_status: MatchIO;
  company_location_status: MatchIO;
  company_naics_status: MatchIO;
  company_revenue_actual_status: MatchIO;
  company_revenue_range_status: MatchIO;
  company_sal_status: MatchIO;
  company_sic_status: MatchIO;
  company_size_actual_status: MatchIO;
  company_size_range_status: MatchIO;
  company_tal_status: MatchIO;
  profile_dual_employment_status: MatchIO;
  profile_location_status: MatchIO;
  profile_num_month_in_current_company_status: MatchIO;
  profile_qualified_contacts_per_company_status: MatchIO;
  profile_workplace_company_name_status: MatchIO;
  profile_workplace_job_title_status: MatchIO;
  contact_personal_number_status: MatchIO | null;
  contact_headquarter_number_status: MatchIO | null;
  contact_script_status: MatchIO | null;
  contact_personal_number_reasons: Reasons | null;
  contact_headquarter_number_reasons: Reasons | null;
}

export interface MatchioInterface extends MatchIOStatuses {
  conclusion_id: string;
}

export type QCofTheLeadData = {
  third_party_data: ThirdPartyData;
  campaign_specification: CompaignSpecificationData;
  lead_data: CompanyPersonCheckData;
  person_profile: PersonProfile;
  company_profile: CompanyProfile;
  matchio: MatchioInterface;
  extra_data: {
    email: string;
  };
};

export enum ConclusionStatus {
  pending = 'pending',
  qualified = 'qualified',
  disqualified = 'disqualified',
  unable_to_verify = 'unable_to_verify',
}

export enum CallNumberState {
  personal_number = 'personal_number',
  headquarter_number = 'headquarter_number',
}

export enum ScriptQuestionAboutInterests {
  it = 'it',
  hr = 'hr',
  marketing = 'marketing',
  accounting = 'accounting',
  sales = 'sales',
  finance = 'finance',
  any_specifics = 'any_specifics',
}

export enum AcceptedAnswers {
  yes = 'yes',
  no = 'no',
}
