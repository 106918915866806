import React, { useMemo } from 'react';
import { Controller, useController } from 'react-hook-form';
import Select from 'react-select';
import { CountryRegionData } from 'react-country-region-selector';

import type { UseFormReturn, FieldErrors } from 'react-hook-form';
import type { SingleValue } from 'react-select';
import { SelectOption } from 'types/models';

import { DropdownIndicator } from 'components/AuthForm/InputField';

import { authSelectStyles } from 'utils/selectStyles';

type Props = {
  formSettings: UseFormReturn<any, object>;
  name: string;
  message?: FieldErrors;
  label?: string;
};

const StateProvinceField = ({ name, formSettings }: Props) => {
  const { field: countryField } = useController({
    name: 'company_country_code',
    control: formSettings.control,
  });

  const { field: stateField } = useController({
    name: name,
    control: formSettings.control,
  });

  const handleStateChange = (val: SingleValue<SelectOption>) => {
    formSettings.resetField('company_city');
    formSettings.resetField('company_address');
    stateField.onChange(val);
  };

  const selectOptions: SelectOption[] = useMemo(() => {
    const countryArrayElement = CountryRegionData.find(
      item => item[1] === countryField?.value?.value
    );
    if (countryArrayElement) {
      const notDividedValues = (countryArrayElement[2] || '').split('|');
      return notDividedValues.map(item => {
        const separatedValue = (item || '').split('~');
        return {
          label: separatedValue[0],
          value: separatedValue[1],
        };
      });
    }
    return [];
  }, [CountryRegionData, countryField?.value?.value]);

  return (
    <Controller
      name={name}
      control={formSettings.control}
      render={({ field: { value, onBlur } }) => (
        <Select<SelectOption>
          value={value as SelectOption}
          onChange={handleStateChange}
          options={selectOptions}
          styles={authSelectStyles}
          placeholder=""
          onBlur={onBlur}
          components={{ DropdownIndicator }}
        />
      )}
    />
  );
};

export default StateProvinceField;
