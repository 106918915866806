import * as Yup from 'yup';
import { isValidPhoneNumber } from 'react-phone-number-input';

import type { AnyObject, Maybe } from 'yup/lib/types';
import type { BaseSchema } from 'yup';

declare module 'yup' {
  interface StringSchema<
    TType extends Maybe<string> = string | undefined,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType
  > extends BaseSchema<TType, TContext, TOut> {
    onlyLettersSpacesNumbers(): StringSchema<TType, TContext>;
    onlyLetterAndSpaces(): StringSchema<TType, TContext>;
    phoneNumber(): StringSchema<TType, TContext>;
    onlyLettersSpacesNumbersComma(): StringSchema<TType, TContext>;
  }
}

export const POSITIVE_NUMBER_REGEXP = /[^0-9]/g;
export const LETTER_SPACES_NUMBERS_COMMA_REGEXP =
  /^[A-Za-z0-9 ,]*[A-Za-z0-9][A-Za-z0-9 ,]*$/;

const REQUIRED_MESSAGE = 'common.error.required';
const MAX_LENGTH_MESSAGE = 'common.error.max-length';
const INVALID_VALUE = 'common.error.invalid-value';

Yup.addMethod<Yup.StringSchema>(Yup.string, 'onlyLetterAndSpaces', function () {
  return this.matches(/^[a-zA-Z\s]*$/, 'common.error.only-letters-spaces');
});

Yup.addMethod<Yup.StringSchema>(
  Yup.string,
  'onlyLettersSpacesNumbers',
  function () {
    return this.matches(
      /^$|^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/,
      'common.error.only-letters-spaces-numbers'
    );
  }
);

Yup.addMethod<Yup.StringSchema>(
  Yup.string,
  'onlyLettersSpacesNumbersComma',
  function () {
    return this.matches(
      LETTER_SPACES_NUMBERS_COMMA_REGEXP,
      'common.error.only-letters-spaces-numbers-comma'
    );
  }
);

Yup.addMethod<Yup.StringSchema>(Yup.string, 'phoneNumber', function () {
  return this.test({
    name: 'phone',
    exclusive: true,
    message: INVALID_VALUE,
    test: value => {
      if (value) {
        return isValidPhoneNumber(value);
      }

      return true;
    },
  });
});

Yup.addMethod<Yup.StringSchema>(Yup.string, 'onlyDigits', function () {
  return this.matches(POSITIVE_NUMBER_REGEXP, 'test');
});

export const validationSchemaSignUpGeneralInfo = Yup.lazy(() =>
  Yup.object().shape({
    first_name: Yup.string()
      .max(30, { key: MAX_LENGTH_MESSAGE, values: { length: 30 } })
      .onlyLetterAndSpaces()
      .required(REQUIRED_MESSAGE),
    last_name: Yup.string()
      .max(30, { key: MAX_LENGTH_MESSAGE, values: { length: 30 } })
      .onlyLetterAndSpaces()
      .required(REQUIRED_MESSAGE),
    phone_number: Yup.string()
      .phoneNumber()
      .typeError(REQUIRED_MESSAGE)
      .required(REQUIRED_MESSAGE),
    date_of_birth: Yup.string().required(REQUIRED_MESSAGE),
    country_code: Yup.object().shape({
      value: Yup.string().required(REQUIRED_MESSAGE),
    }),
  })
);

export const validationSchemaSignUpCompanyInfo = Yup.lazy(() =>
  Yup.object().shape({
    company_name: Yup.string()
      .max(30, { key: MAX_LENGTH_MESSAGE, values: { length: 30 } })
      .required(REQUIRED_MESSAGE),
    job_title: Yup.object().shape({
      value: Yup.string().required(REQUIRED_MESSAGE),
    }),
    department: Yup.object().shape({
      value: Yup.string().required(REQUIRED_MESSAGE),
    }),
    company_country_code: Yup.object().shape({
      value: Yup.string().required(REQUIRED_MESSAGE),
    }),
    company_city: Yup.string().required(REQUIRED_MESSAGE),
    company_state: Yup.mixed().when('company_country_code', {
      is: (country: { value: string } | undefined) => {
        const { value } = country || {};
        const isStateProvinceNeeded = value === 'US' || value === 'CA';

        return value && isStateProvinceNeeded;
      },
      then: Yup.object().shape({
        value: Yup.string().required(REQUIRED_MESSAGE),
      }),
      otherwise: Yup.mixed(),
    }),
    company_address: Yup.string()
      .max(100, { key: MAX_LENGTH_MESSAGE, values: { length: 100 } })
      .typeError(REQUIRED_MESSAGE)
      .required(REQUIRED_MESSAGE),
  })
);
