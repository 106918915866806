import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useClickAway } from 'react-use';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import IconSVG from 'components/common/IconSVG';
import { ReactComponent as LogoutSVG } from 'assets/images/menu/log-out.svg';

import useAuth from 'contexts/AuthContext';

import useEscapeEvent from 'hooks/useEscapeEvent';

import { IconsNames } from 'constants/icons';
import { PrivatePaths } from 'constants/routes';
import { UserRoles } from 'constants/constants';

import styles from './index.module.scss';

const SettingsMenu = (): JSX.Element => {
  const { t } = useTranslation();
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const menuRef = useRef(null);

  const { user, logout } = useAuth();

  const closeMenu = () => setIsMenuVisible(false);

  useEscapeEvent(closeMenu);
  useClickAway(menuRef, closeMenu);

  const handleLogoutClick = () => {
    logout();
  };

  return (
    <>
      <div ref={menuRef}>
        <button
          type="button"
          onClick={() => {
            setIsMenuVisible(prev => !prev);
          }}
          className={styles.button}
        >
          <div className={styles.avatarNameWrapper}>
            <IconSVG name={IconsNames.avatar} />
            <div className={styles.nameWrapper}>
              <span className={styles.name}>{`${user?.first_name[0] || ''}. ${
                user?.last_name || ''
              }`}</span>
              <span className={styles.role}>
                {t(
                  `common.field.${
                    UserRoles[user?.role as keyof typeof UserRoles]
                  }`
                )}
              </span>
            </div>
          </div>

          <IconSVG name={IconsNames.arrowDown} className={styles.arrow} />
        </button>

        {isMenuVisible && (
          <>
            <nav className={cn(styles.nav, styles.settings)}>
              <ul className={styles.list}>
                <li className={styles.li}>
                  <Link to={`/${PrivatePaths.PROFILE}`} className={styles.item}>
                    {t('profile.heading')}
                  </Link>
                </li>
                <li className={styles.li}>
                  <button
                    type="button"
                    className={styles.item}
                    onClick={handleLogoutClick}
                  >
                    <LogoutSVG />
                    {t('common.button.log-out')}
                  </button>
                </li>
              </ul>
            </nav>
          </>
        )}
      </div>
    </>
  );
};

export default SettingsMenu;
