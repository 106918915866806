import React from 'react';

import AstronautPicture from 'assets/images/common/astronaut.png';

import styles from './index.module.scss';

type Props = {
  title: string;
  description?: string;
};

const EmptyContent = ({ title, description }: Props): JSX.Element => {
  return (
    <div className={styles.emptyContentWrapper}>
      <img src={AstronautPicture} className={styles.image} alt={title} />
      <h4>{title}</h4>
      {description && <p className={styles.description}>{description}</p>}
    </div>
  );
};

export default EmptyContent;
