import React, { useEffect } from 'react';

import Loader from 'components/common/LoaderScreen/Loader';

import useLoaderScreen from 'contexts/LoaderScreenContext';

import styles from './index.module.scss';

const LoaderScreen = () => {
  const { openLoaderScreen, closeLoaderScreen } = useLoaderScreen();

  useEffect(() => {
    openLoaderScreen({
      Content: (
        <div className={styles.wrapper} aria-label="loading">
          <Loader />
        </div>
      ),
    });

    return () => closeLoaderScreen();
  }, []);

  return null;
};

export default LoaderScreen;
