import { NavLink } from 'react-router-dom';
import { useState } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import SettingsMenu from 'components/common/PageContainer/Menu/SettingsMenu';

import { PrivatePaths } from 'constants/routes';

import { ReactComponent as DashboardSVG } from 'assets/images/menu/dashboard.svg';
// import { ReactComponent as ReportsSVG } from 'assets/images/menu/reports.svg';
// import { ReactComponent as PaymentsSVG } from 'assets/images/menu/payments.svg';

import styles from './index.module.scss';

type Props = {
  color?: string;
};

const Menu = ({ color = '' }: Props) => {
  const [isOpenMobile, setOpenMobile] = useState(false);
  const { t } = useTranslation();

  const toggleMenu = () => {
    setOpenMobile(!isOpenMobile);
  };

  const getLinkClasses = ({ isActive }: { isActive?: boolean }) =>
    cn(styles.link, {
      [styles.active]: isActive,
    });

  return (
    <>
      <button
        type="button"
        className={cn(styles.burger, {
          [styles['burger--open']]: isOpenMobile,
        })}
        onClick={toggleMenu}
      >
        <div
          className={cn(styles.burgerIcon, {
            [styles['burgerIcon--open']]: isOpenMobile,
          })}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
      </button>
      <div className={styles.wrapper}>
        <div
          className={cn(styles.container, {
            [styles.open]: isOpenMobile,
          })}
          data-id="menu-bar"
          style={
            color
              ? { backgroundColor: `#${color.replace('#', '')}` }
              : undefined
          }
        >
          <nav className={cn(styles.nav)}>
            <NavLink to={PrivatePaths.DASHBOARD} className={styles.logo}>
              <span>TrueQC</span>
            </NavLink>

            <ul className={styles.list}>
              <li>
                <NavLink
                  to={`/${PrivatePaths.DASHBOARD}`}
                  className={getLinkClasses}
                  onClick={toggleMenu}
                >
                  <DashboardSVG className={styles.icon} />
                  <span>{t('dashboard.heading')}</span>
                </NavLink>
              </li>
              {/*------------------Temporary hided------------------*/}
              {/*<li>*/}
              {/*  <NavLink*/}
              {/*    to={`/${PrivatePaths.REPORTS}`}*/}
              {/*    className={getLinkClasses}*/}
              {/*    onClick={toggleMenu}*/}
              {/*  >*/}
              {/*    <ReportsSVG className={styles.icon} />*/}
              {/*    <span>{t('reports.heading')}</span>*/}
              {/*  </NavLink>*/}
              {/*</li>*/}
              {/*<li>*/}
              {/*  <NavLink*/}
              {/*    to={`/${PrivatePaths.PAYMENTS}`}*/}
              {/*    className={getLinkClasses}*/}
              {/*    onClick={toggleMenu}*/}
              {/*  >*/}
              {/*    <PaymentsSVG className={styles.icon} />*/}
              {/*    <span>{t('payments.heading')}</span>*/}
              {/*  </NavLink>*/}
              {/*</li>*/}
            </ul>
          </nav>

          <div className={styles.bottom}>
            <SettingsMenu />
          </div>
        </div>
      </div>
    </>
  );
};

export default Menu;
