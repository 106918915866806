import React, { useState } from 'react';
import PhoneInput from 'react-phone-number-input/mobile';
import { useController } from 'react-hook-form';
import Select from 'react-select';

import type { CSSObjectWithLabel } from 'react-select';
import type { Control } from 'react-hook-form';

import 'react-phone-number-input/style.css';
import styles from './index.module.scss';

type Props = {
  control: Control<any>;
  name: string;
  isBasicStyles?: boolean;
  placeholder?: string;
};

const customStyles = {
  control: (provided: CSSObjectWithLabel) => ({
    ...provided,
    borderStyle: 'none',
    boxShadow: 'none',
    paddingLeft: 0,
  }),
  valueContainer: () => ({
    display: 'none',
  }),
  indicatorContainer: (provided: CSSObjectWithLabel) => ({
    ...provided,
    padding: 0,
  }),
  container: (provided: CSSObjectWithLabel) => ({
    ...provided,
    position: 'static',
    padding: 0,
    marginRight: '4px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  option: (
    provided: CSSObjectWithLabel,
    { isSelected }: { isDisabled: boolean; isSelected: boolean }
  ) => ({
    ...provided,
    backgroundColor: isSelected ? '#f1f1f1' : '#fff',
    color: '#000',
    padding: 10,
    '&:hover': {
      backgroundColor: '#f1f1f1',
    },
  }),
};

const PhoneField = ({ control, name, placeholder }: Props) => {
  const { field } = useController({ control, name });
  const [phone, setPhone] = useState(field?.value);

  return (
    <PhoneInput
      placeholder={placeholder}
      international
      value={phone}
      onChange={x => {
        field.onChange(x);
        setPhone(x);
      }}
      countrySelectComponent={props => {
        return (
          <div className={styles.wrapper}>
            <props.iconComponent
              label={props.label || ''}
              country={props.value}
            />
            <Select
              styles={customStyles}
              {...props}
              onChange={({ value }) => props.onChange(value)}
            />
          </div>
        );
      }}
    />
  );
};

export default PhoneField;
