import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import dayjs from 'dayjs';
import { useMutation } from 'react-query';
import { useLocalStorage } from 'react-use';

import type { AxiosError } from 'axios';
import type { SignUpData } from 'components/SignUpContent';
import type { User } from 'contexts/AuthContext';

import {
  validationSchemaSignUpCompanyInfo,
  validationSchemaSignUpGeneralInfo,
} from 'utils/validations';

import Button from 'components/common/Button';
import AuthForm from 'components/AuthForm';
import LoaderScreen from 'components/common/LoaderScreen';

import {
  companyInfoFields,
  generalInfoFields,
  FormType,
} from 'components/SignUpContent';
import { IconsNames } from 'constants/icons';
import { AuthFormFieldTypes, LocalStorageKeys } from 'constants/constants';
import { API_ENDPOINTS } from 'constants/endpoints';

import useAuth from 'contexts/AuthContext';

import styles from './index.module.scss';

const ProfileTabs = () => {
  const { user, axios, setState } = useAuth();
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState<FormType>(FormType.general);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [, setIsProfileFilled] = useLocalStorage(
    LocalStorageKeys.IS_PROFILE_COMPLETED
  );

  const regionNames = new Intl.DisplayNames([i18n.language], {
    type: 'region',
  });

  const isGeneralTab = activeTab === FormType.general;

  const { mutate, isLoading, error } = useMutation<
    SignUpData,
    AxiosError,
    SignUpData
  >(
    async userData => {
      try {
        const { data } = await axios.patch<SignUpData>(
          `${process.env.REACT_APP_BACKEND_URL}${API_ENDPOINTS.profile}`,
          userData
        );

        return data;
      } catch (err) {
        throw err;
      }
    },
    {
      onSuccess: responseBody => {
        const userData = responseBody as User;
        setState({ user: userData });
        setIsProfileFilled(userData.is_profile_complete);
        setIsEditMode(false);
      },
    }
  );

  const handleChangeActiveTab = () => {
    setActiveTab(
      activeTab === FormType.general ? FormType.company : FormType.general
    );
  };

  const handleSubmit = (data: SignUpData) => {
    const initUserData = { ...user };
    delete initUserData.email;
    delete initUserData.is_profile_complete;
    delete initUserData.role;

    if (isGeneralTab) {
      const generalDataToSend = {
        ...(initUserData as User),
        first_name: data.first_name,
        last_name: data.last_name,
        date_of_birth: data.date_of_birth,
        phone_number: data.phone_number,
        timezone:
          typeof data.timezone !== 'string' ? data.timezone?.value || '' : '',
        country_code:
          typeof data.country_code !== 'string' ? data.country_code.value : '',
      };
      mutate(generalDataToSend);
    } else {
      const companyDataToSend = {
        ...(initUserData as User),
        company_name: data.company_name,
        company_city: data.company_city,
        company_address: data.company_address,
        company_country_code:
          typeof data.company_country_code !== 'string'
            ? data.company_country_code.value
            : '',
        job_title:
          typeof data.job_title !== 'string'
            ? data.job_title.value
            : data.job_title,
        department:
          typeof data.department !== 'string' ? data.department.value : '',
        company_state:
          typeof data.company_state !== 'string'
            ? data.company_state?.value
            : '',
      } as SignUpData;
      if (
        companyDataToSend.company_country_code !== 'US' &&
        companyDataToSend.company_country_code !== 'CA'
      ) {
        delete companyDataToSend.company_state;
      }
      mutate(companyDataToSend);
    }
    if (data.email) {
      setIsEditMode(!isEditMode);
    }
  };

  const generalInfo = [
    {
      label: 'country',
      value: user?.country_code
        ? regionNames.of(user?.country_code || '')
        : '-',
    },
    {
      label: 'date-of-birth',
      value: user?.date_of_birth
        ? dayjs(user?.date_of_birth).format('DD/MM/YYYY')
        : '-',
    },
    {
      label: 'email',
      value: user?.email || '-',
    },
    {
      label: 'phone',
      value: user?.phone_number || '-',
    },
    {
      label: 'timezone',
      value: user?.timezone || '-',
    },
  ];

  const companyInfo = [
    {
      label: 'name',
      value: user?.company_name,
    },
    {
      label: 'job-title',
      value: user?.job_title || '-',
    },
    {
      label: 'company-country',
      value: user?.company_country_code
        ? regionNames.of(user?.company_country_code || '')
        : '-',
    },
    {
      label: 'company-state',
      value: user?.company_state || '-',
    },
    {
      label: 'company-city',
      value: user?.company_city || '-',
    },
    {
      label: 'company-address',
      value: user?.company_address || '-',
    },
  ];

  const defaultValues = {
    ...user,
    country_code: {
      value: user?.country_code,
      label: user?.country_code ? regionNames.of(user?.country_code || '') : '',
    },
    company_country_code: {
      value: user?.company_country_code,
      label: user?.company_country_code
        ? regionNames.of(user?.company_country_code || '')
        : '',
    },
    job_title: {
      value: user?.job_title,
      label: user?.job_title,
    },
    department: {
      value: user?.department,
      label: t(`common.field.${user?.department}`),
    },
    company_state: {
      value: user?.company_state,
      label: user?.company_state,
    },
    timezone: {
      value: user?.timezone || '',
      label: user?.timezone || '',
    },
  };

  return (
    <div className={styles.tabsWrapper}>
      <div className={styles.tabsRow}>
        <div className={styles.tabs}>
          <button
            className={cn(styles.tab, {
              [styles.active]: isGeneralTab,
            })}
            onClick={handleChangeActiveTab}
          >
            {t('common.field.personal')}
          </button>
          <button
            className={cn(styles.tab, {
              [styles.active]: !isGeneralTab,
            })}
            onClick={handleChangeActiveTab}
          >
            {t('common.field.company')}
          </button>
        </div>

        {isEditMode ? (
          <Button
            type="submit"
            form="hook-form"
            className={styles.saveEditButton}
          >
            {t('common.button.save')}
          </Button>
        ) : (
          <Button
            white
            onClick={e => {
              e.preventDefault();
              setIsEditMode(!isEditMode);
            }}
            className={styles.saveEditButton}
            iconProps={{
              name: IconsNames.edit,
              className: styles.buttonIcon,
            }}
          >
            {t('common.button.edit')}
          </Button>
        )}
      </div>
      {isEditMode ? (
        <div className={styles.formWrapper}>
          {isGeneralTab ? (
            <AuthForm<SignUpData>
              fields={[
                ...generalInfoFields,
                {
                  name: 'timezone',
                  type: AuthFormFieldTypes.timezone,
                  required: false,
                  label: 'timezone',
                },
              ]}
              onSubmit={handleSubmit}
              defaultValues={defaultValues}
              validationSchema={validationSchemaSignUpGeneralInfo}
              loading={isLoading}
              hideSubmitButton
              submitError={error}
            />
          ) : (
            <AuthForm<SignUpData>
              fields={companyInfoFields}
              onSubmit={handleSubmit}
              defaultValues={defaultValues}
              validationSchema={validationSchemaSignUpCompanyInfo}
              loading={isLoading}
              hideSubmitButton
              submitError={error}
            />
          )}
        </div>
      ) : (
        <div className={styles.userInfoWrapper}>
          {(isGeneralTab ? generalInfo : companyInfo).map(item => {
            return (
              <div className={styles.item} key={item.label}>
                <span className={styles.label}>
                  {t(`common.field.${item.label}`)}
                </span>
                <span className={styles.value}>{item.value}</span>
              </div>
            );
          })}
        </div>
      )}
      {isLoading && <LoaderScreen />}
    </div>
  );
};

export default ProfileTabs;
