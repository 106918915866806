import React, { useMemo } from 'react';
import { Controller, useController } from 'react-hook-form';
import { CountryRegionData } from 'react-country-region-selector';
import Select from 'react-select';

import type { UseFormReturn, FieldErrors } from 'react-hook-form';
import type { SingleValue } from 'react-select';
import { SelectOption } from 'types/models';

import { authSelectStyles } from 'utils/selectStyles';

import { DropdownIndicator } from 'components/AuthForm/InputField';

type Props = {
  formSettings: UseFormReturn<any, object>;
  name: string;
  message?: FieldErrors;
  label?: string;
};

const CountryField = ({ name, formSettings }: Props) => {
  const { field: countryField } = useController({
    name,
    control: formSettings.control,
  });

  const handleCountryChange = (val: SingleValue<SelectOption>) => {
    if (name === 'company_country_code') {
      formSettings.resetField('company_state');
      formSettings.resetField('company_city');
      formSettings.resetField('company_address');
    }
    countryField.onChange(val);
  };

  const selectOptions: SelectOption[] = useMemo(
    () =>
      CountryRegionData.map(item => ({
        value: item[1],
        label: item[0],
      })),
    CountryRegionData
  );

  return (
    <Controller
      name={name}
      control={formSettings.control}
      render={({ field: { value, onBlur } }) => (
        <Select<SelectOption>
          value={value as SelectOption}
          onChange={handleCountryChange}
          options={selectOptions}
          styles={authSelectStyles}
          placeholder=""
          onBlur={onBlur}
          components={{ DropdownIndicator }}
        />
      )}
    />
  );
};

export default CountryField;
