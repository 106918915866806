import React, { LegacyRef } from 'react';
import cn from 'classnames';

import IconSVG, { IconProps } from 'components/common/IconSVG';

import styles from './index.module.scss';

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  bordered?: boolean;
  iconProps?: IconProps & { isRightPosition?: boolean };
  bold?: boolean;
  uppercase?: boolean;
  white?: boolean;
  lightBlue?: boolean;
  ref?: any;
}

const Button = React.forwardRef(
  (
    {
      type,
      children,
      bordered,
      className,
      iconProps,
      bold,
      uppercase,
      white,
      lightBlue,
      ...props
    }: ButtonProps,
    ref: LegacyRef<HTMLButtonElement>
  ): JSX.Element => {
    const buttonClasses = cn(
      styles.button,
      {
        [styles['button--bordered']]: bordered,
        [styles['button--icon']]: iconProps,
        [styles['button--bold']]: bold,
        [styles['button--uppercase']]: uppercase,
        [styles['button--white']]: white,
        [styles['button--lightBlue']]: lightBlue,
      },
      className
    );

    const iconComponentProps = iconProps && { ...iconProps };
    delete iconComponentProps?.isRightPosition;

    return (
      <button {...props} type={type} className={buttonClasses} ref={ref}>
        {iconComponentProps && <IconSVG {...iconComponentProps} />}
        {children && <span className={styles.text}>{children}</span>}
      </button>
    );
  }
);

export default Button;
