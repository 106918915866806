import React, { useRef, useState } from 'react';
import cn from 'classnames';

import IconSVG from 'components/common/IconSVG';

import { IconsNames } from 'constants/icons';

import styles from './index.module.scss';

type Props = {
  title?: string;
  subtitle?: string;
  icon?: string;
  className?: string;
  onClose?: () => void;
  children?: React.ReactNode;
};

const CallModal = ({
  children,
  title,
  className,
  onClose,
  subtitle,
  icon,
}: Props): JSX.Element => {
  const [isExpandedContent, setIsExpandedContent] = useState<boolean>(true);
  const modalRef = useRef(null);

  const finishCallButton = (
    <div
      className={cn(styles.buttonWrapper, {
        [styles.buttonWrapper__notExpanded]: !isExpandedContent,
      })}
    >
      <button className={styles.endCallButton} onClick={onClose}>
        <IconSVG name={IconsNames.phone} className={styles.phoneIcon} />
      </button>
    </div>
  );

  return (
    <div className={styles.modal}>
      <div ref={modalRef} className={cn(styles.window, className)}>
        <header className={styles.header}>
          <div className={styles.leftSideHeader}>
            {icon && <img src={icon} className={styles.headerIcon} />}
            <div className={styles.headerTitle}>
              {title && <h5 className={styles.title}>{title}</h5>}
              {subtitle && <p className={styles.subtitle}>{subtitle}</p>}
            </div>
          </div>
          <div className={styles.headerButtonWrapper}>
            {!isExpandedContent && finishCallButton}
            <button
              type="button"
              onClick={() => {
                setIsExpandedContent(!isExpandedContent);
              }}
              className={cn(styles.chevronIcon, {
                [styles.chevronIcon__expanded]: isExpandedContent,
              })}
            >
              <IconSVG name={IconsNames.chevronDown} />
            </button>
          </div>
        </header>
        <div
          className={cn(styles.body, {
            [styles.body__expanded]: isExpandedContent,
          })}
          data-testid="script-modal-content"
        >
          {children}
          {finishCallButton}
        </div>
      </div>
    </div>
  );
};

export default CallModal;
