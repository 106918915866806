import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { AxiosError } from 'axios';

import IconSVG from 'components/common/IconSVG';
import Loader from 'components/common/LoaderScreen/Loader';
import ModalWindow from 'components/common/ModalWindow';

import { IconsNames } from 'constants/icons';
import { API_ENDPOINTS } from 'constants/endpoints';

import useAuth from 'contexts/AuthContext';
import useModal from 'contexts/ModalContext';

import styles from './index.module.scss';

const CurrentBalance = () => {
  const { t } = useTranslation();
  const { axios } = useAuth();
  const { openModal } = useModal();

  const { data: balance, isLoading } = useQuery(
    ['balance'],
    async () => axios.get<{ total_points: number }>(API_ENDPOINTS.balance),
    {
      onError: (err: AxiosError) => {
        openModal({
          Content: (
            <ModalWindow
              title={t('common.error.something-went-wrong')}
              errorMessage={err.message}
            />
          ),
        });
      },
    }
  );

  return (
    <section className={styles.banner}>
      {isLoading ? (
        <Loader />
      ) : (
        <div className={styles.statisticWrapper}>
          <div className={styles.icon}>
            <IconSVG name={IconsNames.star} />
          </div>
          <div className={styles.statistic}>
            <span className={styles.value}>
              {balance?.data?.total_points?.toLocaleString() || 0}
            </span>
            <span className={styles.points}>{t('dashboard.points')}</span>
          </div>
        </div>
      )}
      <div className={styles.imageWrapper} />
    </section>
  );
};

export default CurrentBalance;
