import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import PublicRoute from 'components/App/Routes/PublicRoute';
import AuthorizedRoute from 'components/App/Routes/AuthorizedRoute';
import AuthorizedAndNotFilledRoute from 'components/App/Routes/AuthorizedAndNotFilledRoute';

import SignIn from 'views/Auth/LogIn';
import SignUp from 'views/Auth/SignUp';
import Dashboard from 'views/Dashboard';
// import Payments from 'views/Payments';
// import Reports from 'views/Reports';
import Profile from 'views/Profile';
import QCSession from 'views/QCSession';

import { PrivatePaths, PublicPaths } from 'constants/routes';

const App = () => {
  return (
    <Routes>
      <Route element={<AuthorizedRoute />} path={PrivatePaths.INDEX}>
        <Route element={<Dashboard />} path={PrivatePaths.DASHBOARD} />
        <Route element={<Profile />} path={PrivatePaths.PROFILE} />
        <Route element={<QCSession />} path={PrivatePaths.QC_SESSION} />
        {/*----------------Temporary hided----------------------------*/}
        {/*<Route element={<Payments />} path={PrivatePaths.PAYMENTS} />*/}
        {/*<Route element={<Reports />} path={PrivatePaths.REPORTS} />*/}
        <Route index element={<Navigate to={PrivatePaths.DASHBOARD} />} />
      </Route>
      <Route
        element={<AuthorizedAndNotFilledRoute />}
        path={PrivatePaths.INDEX}
      >
        <Route element={<SignUp />} path={PrivatePaths.SIGN_UP} />
      </Route>
      <Route element={<PublicRoute />} path={PublicPaths.INDEX}>
        <Route element={<SignIn />} path={PublicPaths.LOG_IN} />
      </Route>
      <Route path="*" element={<Navigate to={PublicPaths.LOG_IN} />} />
    </Routes>
  );
};

export default App;
