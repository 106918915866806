import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

import { Reasons, SelectOption } from 'types/models';

import { DropdownIndicator } from 'components/AuthForm/InputField';
import Button from 'components/common/Button';

import { IconsNames } from 'constants/icons';
import { MatchIO } from 'constants/constants';
import {
  InvalidContactReason,
  UnableToVerifyContactReason,
  ValidContactReason,
} from 'constants/selectOptions';

import { authSelectStyles } from 'utils/selectStyles';

import styles from './index.module.scss';

type Props = {
  status: MatchIO | null;
  onReasonsApprove: () => void;
  reasons: Reasons | null;
  setReasons: (value: Reasons | null) => void;
};

type ReasonType =
  | InvalidContactReason
  | UnableToVerifyContactReason
  | ValidContactReason;

const ReasonsContent = ({
  onReasonsApprove,
  status,
  reasons,
  setReasons,
}: Props): JSX.Element => {
  const { t } = useTranslation();

  const selectOptions = useMemo(() => {
    const result = (initEnum: Object) => {
      return Object.values(initEnum)
        .filter(
          item =>
            item !== reasons?.primary_reason &&
            item !== reasons?.secondary_reason
        )
        .map(item => ({
          value: item,
          label: t(`qc_session.${item}`),
        }));
    };
    switch (status) {
      case MatchIO.valid:
        return result(ValidContactReason);
      case MatchIO.invalid:
        return result(InvalidContactReason);
      case MatchIO.unable_to_verify:
        return result(UnableToVerifyContactReason);
      default:
        return [];
    }
  }, [status, reasons]);

  const isDisabled = !reasons || !reasons?.primary_reason;

  return (
    <section>
      <div className={styles.selectWrapper}>
        <label htmlFor="firstReason" className={styles.label}>
          {t('qc_session.first-reason')}
        </label>
        <Select<SelectOption>
          value={
            reasons
              ? {
                  value: reasons?.primary_reason,
                  label: t(`qc_session.${reasons?.primary_reason}`),
                }
              : null
          }
          onChange={val => {
            setReasons({
              primary_reason: val?.value as ReasonType,
              secondary_reason: reasons?.secondary_reason || null,
            });
          }}
          options={selectOptions}
          styles={authSelectStyles}
          placeholder={t('qc_session.select-first-reason')}
          components={{ DropdownIndicator }}
          className={styles.select}
          inputId="firstReason"
        />
      </div>
      <div className={styles.selectWrapper}>
        <label htmlFor="secondReason" className={styles.label}>
          {t('qc_session.second-reason')}
        </label>
        <Select<SelectOption>
          value={
            reasons && reasons?.secondary_reason
              ? {
                  value: reasons.secondary_reason,
                  label: t(`qc_session.${reasons.secondary_reason}`),
                }
              : null
          }
          onChange={val => {
            setReasons({
              primary_reason: reasons?.primary_reason as ReasonType,
              secondary_reason: (val?.value as ReasonType) || null,
            });
          }}
          options={selectOptions}
          styles={authSelectStyles}
          placeholder={t('qc_session.select-second-reason')}
          components={{ DropdownIndicator }}
          className={styles.select}
          menuPlacement={'top'}
          isDisabled={isDisabled}
          isClearable
          inputId="secondReason"
        />
      </div>
      <div className={styles.buttonRow}>
        <Button
          iconProps={{
            name: IconsNames.check,
            isRightPosition: false,
          }}
          disabled={isDisabled}
          className={styles.doneButton}
          onClick={onReasonsApprove}
        >
          {t('common.button.done')}
        </Button>
      </div>
    </section>
  );
};

export default ReasonsContent;
