import type { CSSObjectWithLabel } from 'react-select';

export const authSelectStyles = {
  control: (provided: CSSObjectWithLabel) => ({
    ...provided,
    borderRadius: '8px',
    borderColor: '#e8e8e8',
    boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.06)',
    paddingLeft: '12px',
    ':hover': {
      border: '1px solid #21274b',
    },
    ':focus': {
      border: '1px solid #21274b',
    },
  }),
  input: (provided: CSSObjectWithLabel) => ({
    ...provided,
    margin: 0,
    padding: '8px 0',
    color: '#1c1c1c',
  }),
  valueContainer: (provided: CSSObjectWithLabel) => ({
    ...provided,
    padding: 0,
    fontSize: '16px',
    lineHeight: '24px',
    display: 'flex',
  }),
  singleValue: (provided: CSSObjectWithLabel) => ({
    ...provided,
    color: '#1c1c1c',
  }),
  indicatorSeparator: (provided: CSSObjectWithLabel) => ({
    ...provided,
    display: 'none',
  }),
  dropdownIndicator: (provided: CSSObjectWithLabel) => ({
    ...provided,
    color: '#969696',
  }),
  option: (
    provided: CSSObjectWithLabel,
    { isSelected }: { isDisabled: boolean; isSelected: boolean }
  ) => ({
    ...provided,
    backgroundColor: isSelected ? '#f1f1f1' : '#fff',
    color: '#000',
    padding: 10,
    '&:hover': {
      backgroundColor: '#f1f1f1',
    },
  }),
};
