import React from 'react';
import { Navigate } from 'react-router-dom';
import { useLocalStorage } from 'react-use';

import AppLayout from 'layouts/AppLayout';

import { PrivatePaths, PublicPaths } from 'constants/routes';
import { LocalStorageKeys } from 'constants/constants';

const PrivateRoute = (): JSX.Element => {
  const [token] = useLocalStorage<string>(LocalStorageKeys.ACCESS_TOKEN);
  const [isProfileFilled] = useLocalStorage<boolean>(
    LocalStorageKeys.IS_PROFILE_COMPLETED
  );

  return token ? (
    isProfileFilled ? (
      <AppLayout />
    ) : (
      <Navigate to={`/${PrivatePaths.SIGN_UP}`} />
    )
  ) : (
    <Navigate to={`/${PublicPaths.LOG_IN}`} />
  );
};

export default PrivateRoute;
