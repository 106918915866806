export enum IconsNames {
  loading = 'loading',
  google = 'google',
  arrowLeft = 'arrowLeft',
  arrowDown = 'arrowDown',
  error = 'error',
  arrowRight = 'arrowRight',
  selectArrows = 'selectArrows',
  info = 'info',
  avatar = 'avatar',
  star = 'star',
  timer = 'timer',
  like = 'like',
  dislike = 'dislike',
  edit = 'edit',
  flag = 'flag',
  check = 'check',
  companyProfile = 'companyProfile',
  companyProfileCheck = 'companyProfileCheck',
  person = 'person',
  personCheck = 'personCheck',
  smartphoneCheck = 'smartphoneCheck',
  location = 'location',
  globe = 'globe',
  linkedin = 'linkedin',
  closeCircle = 'closeCircle',
  close = 'close',
  questionCircle = 'questionCircle',
  phone = 'phone',
  list = 'list',
  chevronDown = 'chevronDown',
  hello = 'hello',
  alignedList = 'alignedList',
}
