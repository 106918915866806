export const userDepartmentOptions = [
  'c-suite',
  'engineering-and-technical',
  'design',
  'education',
  'finance',
  'human-resources',
  'information-technology',
  'legal',
  'marketing',
  'medical-and-health',
  'operations',
  'sales',
  'consulting',
];

export enum ValidContactReason {
  reached_vm = 'reached_vm',
  reached_prospect = 'reached_prospect',
  prospect_answered_the_call = 'prospect_answered_the_call',
  only_first_name_was_mentioned = 'only_first_name_was_mentioned',
  only_last_name_was_mentioned = 'only_last_name_was_mentioned',
}

export enum InvalidContactReason {
  number_is_not_in_service = 'number_is_not_in_service',
  vm_of_prospect_is_ext_no_only = 'vm_of_prospect_is_ext_no_only',
  company_vm = 'company_vm',
  answered_by_other_person = 'answered_by_other_person',
  invalid_extension = 'invalid_extension',
  only_phone_number_on_voicemail = 'only_phone_number_on_voicemail',
  prospect_has_left_the_company = 'prospect_has_left_the_company',
  different_name_was_mentioned_on_vm = 'different_name_was_mentioned_on_vm',
  prospect_retired = 'prospect_retired',
  prospect_deceased = 'prospect_deceased',
}

export enum UnableToVerifyContactReason {
  could_not_reach_the_prospect = 'could_not_reach_the_prospect',
  no_available_vm = 'no_available_vm',
  no_answer = 'no_answer',
  number_is_busy = 'number_is_busy',
  name_was_not_included_on_the_directory = 'name_was_not_included_on_the_directory',
  call_disconnected = 'call_disconnected',
  gatekeeper_refused_to_put_in_vm = 'gatekeeper_refused_to_put_in_vm',
  prospect_is_unavailable = 'prospect_is_unavailable',
  prospect_works_remotely = 'prospect_works_remotely',
  ivr = 'ivr',
  french_ivr = 'french_ivr',
  no_name_mentioned_on_vm = 'no_name_mentioned_on_vm',
}
