import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PrivatePaths } from 'constants/routes';

import Button from 'components/common/Button';
import PageContainer from 'components/common/PageContainer';
import IconSVG from 'components/common/IconSVG';
import CurrentBalance from 'components/DashboardContent/CurrentBalance';
import LeadStatistics from 'components/DashboardContent/LeadStatistics';

import useAuth from 'contexts/AuthContext';

import { IconsNames } from 'constants/icons';

import styles from './index.module.scss';

const DashboardContent = () => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const title = `${t('dashboard.hello')}, ${user?.first_name} 👋`;

  const startQCSessionButton = (
    <Button onClick={() => navigate(`/${PrivatePaths.QC_SESSION}`)}>
      <div className={styles.buttonContent}>
        {t('common.button.start-session')}
        <IconSVG name={IconsNames.arrowRight} />
      </div>
    </Button>
  );

  return (
    <PageContainer
      title={title}
      isWhiteBackground
      rightComponent={startQCSessionButton}
    >
      <div className={styles.currentBalanceWrapper}>
        <h4>{t('dashboard.current-balance')}</h4>
        <CurrentBalance />
      </div>
      <LeadStatistics />
    </PageContainer>
  );
};

export default DashboardContent;
