import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import CreatableSelect from 'react-select/creatable';
import { components } from 'react-select';

import type { InputProps, StylesConfig } from 'react-select';
import { SelectOption } from 'types/models';

import { DropdownIndicator } from 'components/AuthForm/InputField';

import { API_ENDPOINTS } from 'constants/endpoints';

import useAuth from 'contexts/AuthContext';

type Props = {
  field: any;
  styles?: StylesConfig;
  placeholder?: string;
};

const Input = (props: InputProps) => {
  return (
    <components.Input
      {...props}
      autoComplete={`password-${props.selectProps.name}`}
      id={`password-${props.selectProps.name}`}
    />
  );
};

const JobTitleField = ({ field, styles, placeholder }: Props) => {
  const { axios } = useAuth();
  const { t } = useTranslation();

  const { data: options, isLoading } = useQuery(
    ['job-titles'],
    async () => {
      try {
        const { data } = await axios.get<{ name: string }[]>(
          API_ENDPOINTS.jobTitles
        );

        return data.length
          ? data.map(value => ({
              value: value.name,
              label: value.name,
            }))
          : [];
      } catch (error) {
        throw error;
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const selectOptions = options || [];

  return (
    <CreatableSelect<SelectOption>
      loadingMessage={() => t('common.field.loading')}
      formatCreateLabel={value => `${t('common.button.create')} "${value}"`}
      isLoading={isLoading}
      styles={styles}
      options={selectOptions}
      {...field}
      placeholder={placeholder || ''}
      components={{
        Input,
        DropdownIndicator,
      }}
      noOptionsMessage={() => t('common.field.no-options')}
      hideSelectedOptions
      closeMenuOnSelect
    />
  );
};

export default JobTitleField;
