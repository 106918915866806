import React from 'react';
import { useTranslation } from 'react-i18next';

import { CallNumberState, MatchIOFields, MatchIOStatuses } from 'types/models';

import InfoBlock from 'components/QCSessionContent/InfoBlock';
import IconSVG from 'components/common/IconSVG';
import Button from 'components/common/Button';
import MatchIOSelect from 'components/QCSessionContent/MatchIOSelect';

import { IconsNames } from 'constants/icons';
import { MatchIO } from 'constants/constants';

import styles from './index.module.scss';

type Props = {
  onChangeStatus: (name: MatchIOFields, value: MatchIO) => void;
  statuses: MatchIOStatuses;
  onCallClick: (callableButton: CallNumberState) => void;
};

const ContactVerification = ({
  onChangeStatus,
  statuses,
  onCallClick,
}: Props): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={styles.contactCheckRowWrapper}>
      <div className={styles.columnWrapper}>
        <InfoBlock title={t('qc_session.email-validation')}>
          <div className={styles.row}>
            <IconSVG name={IconsNames.check} className={styles.checkIcon} />
            <span>{t('common.field.valid')}</span>
          </div>
        </InfoBlock>
      </div>
      <div className={styles.columnWrapper}>
        <InfoBlock title={t('qc_session.personal-number')}>
          <div className={styles.row}>
            {statuses.contact_personal_number_status !== null ? (
              <MatchIOSelect
                value={{
                  value: statuses.contact_personal_number_status,
                  label: t(
                    `common.field.${statuses.contact_personal_number_status}`
                  ),
                }}
                name={MatchIOFields.contact_personal_number_status}
                onChangeMatchIOStatus={onChangeStatus}
                isDisabled
              />
            ) : (
              <Button
                onClick={() => onCallClick(CallNumberState.personal_number)}
                className={styles.callButton}
                iconProps={{
                  name: IconsNames.phone,
                  isRightPosition: false,
                }}
              >
                {t('qc_session.call')}
              </Button>
            )}
          </div>
        </InfoBlock>
      </div>
      <div className={styles.columnWrapper}>
        <InfoBlock title={t('qc_session.headquarter_number')}>
          <div className={styles.row}>
            {statuses.contact_headquarter_number_status !== null ? (
              <MatchIOSelect
                value={{
                  value: statuses.contact_headquarter_number_status,
                  label: t(
                    `common.field.${statuses.contact_headquarter_number_status}`
                  ),
                }}
                name={MatchIOFields.contact_headquarter_number_status}
                onChangeMatchIOStatus={onChangeStatus}
                isDisabled
              />
            ) : (
              <Button
                onClick={() => onCallClick(CallNumberState.headquarter_number)}
                className={styles.callButton}
                iconProps={{
                  name: IconsNames.phone,
                  isRightPosition: false,
                }}
              >
                {t('qc_session.call')}
              </Button>
            )}
          </div>
        </InfoBlock>
      </div>
      <div className={styles.columnWrapper}>
        <InfoBlock title={t('qc_session.script-status')}>
          <div className={styles.row} data-testid="script-status-select">
            <MatchIOSelect
              value={
                statuses.contact_script_status
                  ? {
                      value: statuses.contact_script_status,
                      label: t(
                        `common.field.${statuses.contact_script_status}`
                      ),
                    }
                  : null
              }
              name={MatchIOFields.contact_script_status}
              onChangeMatchIOStatus={onChangeStatus}
              placeholder={t('common.field.select')}
            />
          </div>
        </InfoBlock>
      </div>
    </div>
  );
};

export default ContactVerification;
