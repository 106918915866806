import React from 'react';
import { useTranslation } from 'react-i18next';

import { PersonProfile } from 'types/models';

import InfoBlock from 'components/QCSessionContent/InfoBlock';

import styles from './index.module.scss';

type Props = {
  data: PersonProfile;
};

const PersonInfo = ({ data }: Props): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={styles.profileRowWrapper}>
      <div className={styles.columnWrapper}>
        <InfoBlock title={t('common.field.first-name')}>
          <p>{data.first_name}</p>
        </InfoBlock>
      </div>
      <div className={styles.columnWrapper}>
        <InfoBlock title={t('common.field.last-name')}>
          <p>{data.last_name}</p>
        </InfoBlock>
      </div>
      <div className={styles.columnWrapper}>
        <InfoBlock title={t('common.field.connections')}>
          <p>{data.connections_count}</p>
        </InfoBlock>
      </div>
      <div className={styles.columnWrapper}>
        <InfoBlock title={t('common.field.about')}>
          <p>{data.about}</p>
        </InfoBlock>
      </div>
    </div>
  );
};

export default PersonInfo;
