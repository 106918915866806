import styles from './index.module.scss';

type Props = {
  title: string;
  rightComponent?: React.ReactNode | null;
};

const PageTitle = ({ title, rightComponent }: Props) => {
  return (
    <div className={styles.wrappRow}>
      <h3 className={styles.title}>{title}</h3>
      {rightComponent && <div>{rightComponent}</div>}
    </div>
  );
};

export default PageTitle;
