import axios, { AxiosError, AxiosResponse } from 'axios';
import React, { createContext, useState, useContext, useMemo } from 'react';
import { useLocalStorage } from 'react-use';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import type { AxiosInstance } from 'axios';

import { PublicPaths } from 'constants/routes';
import { LocalStorageKeys, UserRoles } from 'constants/constants';
import { API_ENDPOINTS } from 'constants/endpoints';

import useModal from 'contexts/ModalContext';

import LoaderScreen from 'components/common/LoaderScreen';

interface Props {
  children: React.ReactNode;
}

export type User = {
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  date_of_birth: string;
  job_title: string;
  department: string;
  company_name: string;
  company_country_code: string;
  company_state: string;
  company_city: string;
  company_address: string;
  is_profile_complete: boolean;
  role: keyof typeof UserRoles | string | undefined;
  timezone: string;
  country_code: string;
};

type State = {
  user: null | User;
};

const initialState = {
  user: null,
  setState: () => {},
  axios,
  logout: () => {},
};

export const AuthContext = createContext<
  State & {
    setState: (state: State) => void;
    axios: AxiosInstance;
    logout: () => void;
  }
>(initialState);

export const AuthProvider = ({ children }: Props): JSX.Element => {
  const [state, setState] = useState<State>({ user: null });
  const [accessToken, setAccessToken, removeAccessToken] =
    useLocalStorage<string>(LocalStorageKeys.ACCESS_TOKEN);
  const [, setRefreshToken, removeRefreshToken] = useLocalStorage<string>(
    LocalStorageKeys.REFRESH_TOKEN
  );
  const [, setIsProfileFilled, removeIsProfileFilled] =
    useLocalStorage<boolean>(LocalStorageKeys.IS_PROFILE_COMPLETED);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { closeModal } = useModal();

  const logoutClean = () => {
    closeModal();
    removeAccessToken();
    removeRefreshToken();
    removeIsProfileFilled();
    setState({ user: null });
    queryClient.clear();
    navigate(PublicPaths.LOG_IN);
  };

  const axiosInstance = useMemo(() => {
    const AxiosInstance = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_URL,
    });

    let isRefreshing = false;
    let failedQueue: {
      resolve: (value: string | null) => void;
      reject: (reason?: string) => void;
    }[] = [];

    const processQueue = (error: string | null, token = null) => {
      failedQueue.forEach(prom => {
        if (error) {
          prom.reject(error);
        } else {
          prom.resolve(token);
        }
      });
      failedQueue = [];
    };

    const clearRefresh = () => {
      isRefreshing = false;
      failedQueue = [];
    };

    AxiosInstance.interceptors.request.use(config => {
      const tokenString = localStorage.getItem(LocalStorageKeys.ACCESS_TOKEN);
      const token: string | null = tokenString ? JSON.parse(tokenString) : null;

      if (!token) {
        logoutClean();
      }

      return {
        ...config,
        headers: {
          'Content-Type': 'application/json',
          authorization: token ? `Bearer ${token}` : '',
        },
      };
    });

    AxiosInstance.interceptors.response.use(
      response => response,
      async error => {
        const config = error.config;
        const isUnauthorized = error.response && error.response.status === 401;
        const isSessionExpired =
          error.response &&
          error.response.status === 409 &&
          error.response.data.code === 'unable_to_perform';

        if (isSessionExpired) {
          logoutClean();
          clearRefresh();
        }

        if (isUnauthorized && !config._retry) {
          if (config.url === `${API_ENDPOINTS.refreshToken}`) {
            logoutClean();
            clearRefresh();
          } else {
            if (error.response.data.code === 'token_has_expired') {
              if (isRefreshing) {
                return new Promise((resolve, reject) => {
                  failedQueue.push({ resolve, reject });
                })
                  .then(token => {
                    config.headers.authorization = `Bearer ${token}`;
                    return axios(config);
                  })
                  .catch(err => {
                    return Promise.reject(err);
                  });
              }

              config._retry = true;
              isRefreshing = true;

              const refreshTokenString = localStorage.getItem(
                LocalStorageKeys.REFRESH_TOKEN
              );
              const refreshToken: string | null =
                refreshTokenString && JSON.parse(refreshTokenString);

              const { data } = await axiosInstance.post(
                `${process.env.REACT_APP_BACKEND_URL}${API_ENDPOINTS.refreshToken}`,
                {
                  refresh_token: refreshToken,
                }
              );

              if (data) {
                setAccessToken(data.access_token);
                setRefreshToken(data.refresh_token);

                config.headers = {
                  'Content-Type': 'application/json',
                  authorization: `Bearer ${data.access_token}`,
                };
                processQueue(null, data.access_token);
                isRefreshing = false;
                return axios(config);
              }
            }
          }
        }
        return Promise.reject(error);
      }
    );

    return AxiosInstance;
  }, []);

  const { mutate: logout, isLoading: isLogoutLoading } =
    useMutation<AxiosResponse>(
      async () => {
        try {
          const { data } = await axiosInstance.post(
            `${process.env.REACT_APP_BACKEND_URL}${API_ENDPOINTS.logout}`
          );
          return data;
        } catch (err) {
          throw err;
        }
      },
      {
        onSuccess: async () => {
          logoutClean();
        },
      }
    );

  const { isLoading } = useQuery<User, AxiosError>(
    ['profile'],
    async () => {
      try {
        const user = await axiosInstance.get<User>(API_ENDPOINTS.profile);

        return user.data;
      } catch (err) {
        throw err;
      }
    },
    {
      onSuccess: (userData: User) => {
        setState({ user: userData });
        setIsProfileFilled(userData.is_profile_complete);
      },
      onError: () => {
        logout();
      },
      staleTime: Infinity,
      enabled: !!accessToken && !state.user?.email,
    }
  );

  const value = {
    user: state.user,
    setState,
    axios: axiosInstance,
    logout,
  };

  return (
    <AuthContext.Provider value={value}>
      {(isLoading || isLogoutLoading) && <LoaderScreen />}
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => useContext(AuthContext);

export default useAuth;
