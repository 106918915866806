import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { Reasons } from 'types/models';

import IconSVG from 'components/common/IconSVG';
import StatusesContent from 'components/QCSessionContent/CallStatusModal/StatusesContent';
import ReasonsContent from 'components/QCSessionContent/CallStatusModal/ReasonsContent';

import { IconsNames } from 'constants/icons';
import { MatchIO } from 'constants/constants';

import styles from './index.module.scss';

type Props = {
  onDoneClick: (status: MatchIO, reasons: Reasons) => void;
};

const CallStatusModal = ({ onDoneClick }: Props): JSX.Element => {
  const [step, setStep] = useState<number>(1);
  const [status, setStatus] = useState<MatchIO | null>(null);
  const [reasons, setReasons] = useState<Reasons | null>(null);

  const { t } = useTranslation();
  const modalRef = useRef(null);

  const isStatusStep = step === 1;

  const handleBackToStatusesClick = () => {
    setStep(1);
  };

  const handleSubmitStatusReasons = () => {
    if (status && reasons) {
      onDoneClick(status, reasons);
    }
  };

  return (
    <div className={styles.modal}>
      <div
        ref={modalRef}
        className={cn(styles.window, { [styles.reasonWindow]: !isStatusStep })}
      >
        {isStatusStep ? (
          <header className={styles.header}>
            <h4>{t('qc_session.what-is-call-status')}</h4>
          </header>
        ) : (
          <header className={styles.reasonsHeader}>
            <IconSVG
              name={IconsNames.arrowLeft}
              className={styles.arrowLeftIcon}
              onClick={handleBackToStatusesClick}
            />
            <h4>{t('qc_session.what-is-the-reason')}</h4>
          </header>
        )}
        {isStatusStep ? (
          <StatusesContent
            status={status}
            setStatus={setStatus}
            setReasons={setReasons}
            onGoToReasons={() => setStep(2)}
          />
        ) : (
          <ReasonsContent
            onReasonsApprove={handleSubmitStatusReasons}
            status={status}
            reasons={reasons}
            setReasons={setReasons}
          />
        )}
      </div>
    </div>
  );
};

export default CallStatusModal;
