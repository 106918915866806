export enum PublicPaths {
  INDEX = '/',
  LOG_IN = 'log-in',
}

export enum PrivatePaths {
  INDEX = '/',
  DASHBOARD = 'dashboard',
  SIGN_UP = 'sign-up',
  PROFILE = 'profile',
  REPORTS = 'reports',
  PAYMENTS = 'payments',
  QC_SESSION = 'qc_session',
}
