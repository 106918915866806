import React, { createContext, useState, useRef, useContext } from 'react';
import ReactDOM from 'react-dom';

type Props = {
  children: React.ReactNode;
};

type State = {
  visible: boolean;
  Content: React.ReactNode | null | ((props: unknown) => JSX.Element);
};

const initialState = {
  visible: false,
  Content: null,
};

export const ModalContext = createContext<{
  openModal: (data: Omit<State, 'visible'>) => void;
  closeModal: () => void;
  isModalVisible: boolean;
}>({
  openModal: () => {},
  closeModal: () => {},
  isModalVisible: false,
});

export const ModalProvider = ({ children }: Props): JSX.Element => {
  const [state, setState] = useState<State>(initialState);
  const modalContainerRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const { visible, Content } = state;

  const openModal = (data: Omit<State, 'visible'>) => {
    setState({ ...data, visible: true });
  };
  const closeModal = () => setState(initialState);

  const value = {
    openModal,
    closeModal,
    isModalVisible: visible,
  };

  const isModalVisible = visible && modalContainerRef;

  return (
    <ModalContext.Provider value={value}>
      <div ref={modalContainerRef} />
      {children}
      {isModalVisible &&
        ReactDOM.createPortal(
          typeof Content === 'function' ? <Content /> : <>{Content}</>,
          modalContainerRef.current
        )}
    </ModalContext.Provider>
  );
};

const useModal = () => useContext(ModalContext);

export default useModal;
