import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { Reasons } from 'types/models';

import Button from 'components/common/Button';
import IconSVG from 'components/common/IconSVG';

import VerifiedIcon from 'assets/images/qcSession/verified.svg';

import { MatchIO } from 'constants/constants';
import { IconsNames } from 'constants/icons';

import styles from './index.module.scss';

type Props = {
  status: MatchIO | null;
  setStatus: (value: MatchIO) => void;
  onGoToReasons: () => void;
  setReasons: (value: Reasons | null) => void;
};

const StatusesContent = ({
  setStatus,
  status,
  onGoToReasons,
  setReasons,
}: Props): JSX.Element => {
  const { t } = useTranslation();

  const handleChangeStatus = (newStatus: MatchIO) => {
    setStatus(newStatus);
    setReasons(null);
  };

  return (
    <section>
      <div className={styles.buttonRow}>
        <button
          className={cn(styles.button, {
            [styles.button__selected]: status === MatchIO.valid,
          })}
          onClick={() => handleChangeStatus(MatchIO.valid)}
        >
          <img src={VerifiedIcon} alt={t('common.field.valid')} />
        </button>
        <button
          className={cn(styles.button, {
            [styles.button__selected]: status === MatchIO.invalid,
          })}
          onClick={() => handleChangeStatus(MatchIO.invalid)}
        >
          <IconSVG
            name={IconsNames.closeCircle}
            className={styles.invalidIcon}
          />
        </button>
        <button
          className={cn(styles.button, {
            [styles.button__selected]: status === MatchIO.unable_to_verify,
          })}
          onClick={() => handleChangeStatus(MatchIO.unable_to_verify)}
        >
          <IconSVG
            name={IconsNames.questionCircle}
            className={styles.unableToVerifyIcon}
          />
        </button>
      </div>
      <div className={styles.buttonRow}>
        <Button
          iconProps={{
            name: IconsNames.check,
            isRightPosition: false,
          }}
          disabled={!status}
          className={styles.doneButton}
          onClick={onGoToReasons}
          name="submitStatus"
          role={'button'}
        >
          {t('common.button.done')}
        </Button>
      </div>
    </section>
  );
};

export default StatusesContent;
