import React, { useState } from 'react';
import DayPicker from 'react-datepicker';
import cn from 'classnames';
import dayjs from 'dayjs';

import styles from './index.module.scss';
import 'react-datepicker/dist/react-datepicker.css';

type Props = {
  value: string;
  onChange: (newDate: string) => void;
  isMonthly?: boolean;
  label?: string;
  wrapperClassName?: string;
  placeholder?: string;
  fromMonth?: Date;
  toMonth?: Date;
  inputClassName?: string;
  validationError?: string;
  maxErrorLength?: number;
};

const currentYear = new Date().getFullYear();
const fromMonthDefault = new Date(currentYear, 0);
const toMonthDefault = new Date(currentYear + 10, 11);

const DatePicker = ({
  value,
  onChange,
  placeholder,
  fromMonth = fromMonthDefault,
  toMonth = toMonthDefault,
  inputClassName,
}: Props) => {
  const [customValue, setCustomValue] = useState(
    value ? dayjs(value).toDate() : null
  );

  const onDayChange = (day: Date) => {
    if (!day) {
      return onChange('');
    }

    setCustomValue(day);

    return onChange(dayjs(day).format('YYYY-MM-DD'));
  };

  return (
    <DayPicker
      selected={customValue || null}
      onChange={onDayChange}
      dateFormat="MM/dd/yyyy"
      calendarClassName={styles.cal}
      className={cn(styles.input, inputClassName)}
      wrapperClassName={styles.wrapper}
      dayClassName={() => styles.day}
      placeholderText={placeholder}
      minDate={fromMonth}
      maxDate={toMonth}
      showMonthDropdown
      showYearDropdown
    />
  );
};

export default DatePicker;
