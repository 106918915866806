import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

import {
  MatchIOFields,
  MatchIOStatuses,
  ProfileWorkplace,
  QCofTheLeadData,
} from 'types/models';

import Table from 'components/common/Table';
import MatchIOSelect from 'components/QCSessionContent/MatchIOSelect';
import { DropdownIndicator } from 'components/AuthForm/InputField';

import { renderList } from 'components/QCSessionContent/CompanyCheck';
import { authSelectStyles } from 'utils/selectStyles';

import { MatchIO, NoValueFromBE } from 'constants/constants';

import styles from './index.module.scss';

type Props = {
  data: QCofTheLeadData;
  onChangeStatus: (name: MatchIOFields, value: MatchIO) => void;
  statuses: MatchIOStatuses;
};

enum TableAccessors {
  rowName = 'rowName',
  actualData = 'actualData',
  campaignSpecification = 'campaignSpecification',
  report = 'report',
  matchIO = 'matchIO',
}

export const MaxLinkLength = 40;

const PersonCheck = ({
  data,
  statuses,
  onChangeStatus,
}: Props): JSX.Element => {
  const { t } = useTranslation();

  const renderLink = (href: string) =>
    href === NoValueFromBE ? (
      href
    ) : (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        className={styles.link}
        key={href}
      >
        {href.length > MaxLinkLength
          ? `${href.slice(0, MaxLinkLength)}...`
          : href}
      </a>
    );

  const getWorkplaces = () => {
    let resultArray: TableAccessors[] = [];
    (data.third_party_data.profile_workplaces || []).forEach(
      (item: ProfileWorkplace, index: number) => {
        const title = {
          [TableAccessors.rowName]: `${t('common.field.title')} (${index + 1})`,
          [TableAccessors.actualData]: item.title,
          [TableAccessors.campaignSpecification]:
            typeof data.campaign_specification?.profile_workplaces[index]
              ?.title === 'string'
              ? data.campaign_specification?.profile_workplaces[index]?.title
              : renderList(
                  // @ts-ignore
                  data.campaign_specification?.profile_workplaces[index]?.title
                ),
          [TableAccessors.report]:
            data.lead_data.profile_workplaces[index].title,
          [TableAccessors.matchIO]:
            index === 0
              ? {
                  value:
                    statuses[MatchIOFields.profile_workplace_job_title_status],
                  name: MatchIOFields.profile_workplace_job_title_status,
                }
              : null,
        };
        const companyName = {
          [TableAccessors.rowName]: `${t('common.field.companyName')} (${
            index + 1
          })`,
          [TableAccessors.actualData]: item.company_name,
          [TableAccessors.campaignSpecification]:
            data.campaign_specification.profile_workplaces[index].company_name,
          [TableAccessors.report]:
            data.lead_data.profile_workplaces[index].company_name,
          [TableAccessors.matchIO]:
            index === 0
              ? {
                  value:
                    statuses[
                      MatchIOFields.profile_workplace_company_name_status
                    ],
                  name: MatchIOFields.profile_workplace_company_name_status,
                }
              : null,
        };
        const periodOfWork = {
          [TableAccessors.rowName]: `${t('common.field.date')} (${index + 1})`,
          [TableAccessors.actualData]: item.date,
          [TableAccessors.campaignSpecification]:
            data.campaign_specification.profile_workplaces[index].date,
          [TableAccessors.report]:
            data.lead_data.profile_workplaces[index].date,
          [TableAccessors.matchIO]: null,
        };
        resultArray = [
          ...resultArray,
          title,
          companyName,
          periodOfWork,
        ] as TableAccessors[];
      }
    );
    return resultArray;
  };

  const rowsData = useMemo(() => {
    return [
      {
        [TableAccessors.rowName]: t('common.field.link'),
        [TableAccessors.actualData]: renderLink(
          data.third_party_data.profile_link
        ),
        [TableAccessors.campaignSpecification]: renderLink(
          data.campaign_specification.profile_link
        ),
        [TableAccessors.report]: renderLink(data.lead_data.profile_link),
        [TableAccessors.matchIO]: null,
      },
      {
        [TableAccessors.rowName]: t('common.field.first-name'),
        [TableAccessors.actualData]: data.third_party_data.profile_first_name,
        [TableAccessors.campaignSpecification]:
          data.campaign_specification.profile_first_name,
        [TableAccessors.report]: data.lead_data.profile_first_name,
        [TableAccessors.matchIO]: null,
      },
      {
        [TableAccessors.rowName]: t('common.field.last-name'),
        [TableAccessors.actualData]: data.third_party_data.profile_last_name,
        [TableAccessors.campaignSpecification]:
          data.campaign_specification.profile_last_name,
        [TableAccessors.report]: data.lead_data.profile_last_name,
        [TableAccessors.matchIO]: null,
      },
      {
        [TableAccessors.rowName]: t('common.field.location'),
        [TableAccessors.actualData]: data.third_party_data.profile_location,
        [TableAccessors.campaignSpecification]:
          data.campaign_specification.profile_location,
        [TableAccessors.report]: data.lead_data.profile_location,
        [TableAccessors.matchIO]: {
          value: statuses[MatchIOFields.profile_location_status],
          name: MatchIOFields.profile_location_status,
        },
      },
      ...getWorkplaces(),
      {
        [TableAccessors.rowName]: t('common.field.dualEmployment'),
        [TableAccessors.actualData]:
          data.third_party_data.profile_dual_employment,
        [TableAccessors.campaignSpecification]:
          data.campaign_specification.profile_dual_employment,
        [TableAccessors.report]: data.lead_data.profile_dual_employment,
        [TableAccessors.matchIO]: {
          value: statuses[MatchIOFields.profile_dual_employment_status],
          name: MatchIOFields.profile_dual_employment_status,
        },
      },
      {
        [TableAccessors.rowName]: t('common.field.education'),
        [TableAccessors.actualData]: data.third_party_data.profile_education,
        [TableAccessors.campaignSpecification]:
          data.campaign_specification.profile_education,
        [TableAccessors.report]: data.lead_data.profile_education,
        [TableAccessors.matchIO]: null,
      },
      {
        [TableAccessors.rowName]: t('common.field.numbOfYearsInComp'),
        [TableAccessors.actualData]:
          data.third_party_data.profile_num_month_in_current_company,
        [TableAccessors.campaignSpecification]:
          data.campaign_specification.profile_num_month_in_current_company,
        [TableAccessors.report]:
          data.lead_data.profile_num_month_in_current_company,
        [TableAccessors.matchIO]: {
          value:
            statuses[MatchIOFields.profile_num_month_in_current_company_status],
          name: MatchIOFields.profile_num_month_in_current_company_status,
        },
      },
      {
        [TableAccessors.rowName]: t('common.field.contactsPerCompany'),
        [TableAccessors.actualData]:
          data.third_party_data.profile_qualified_contacts_per_company,
        [TableAccessors.campaignSpecification]:
          data.campaign_specification.profile_qualified_contacts_per_company,
        [TableAccessors.report]:
          data.lead_data.profile_qualified_contacts_per_company,
        [TableAccessors.matchIO]: {
          value:
            statuses[
              MatchIOFields.profile_qualified_contacts_per_company_status
            ],
          name: MatchIOFields.profile_qualified_contacts_per_company_status,
        },
      },
    ];
  }, [statuses]);

  const columnsData = useMemo(
    () => [
      {
        Header: '',
        accessor: TableAccessors.rowName,
        fixed: 'left',
      },
      {
        Header: t('common.field.campaignSpecification'),
        accessor: TableAccessors.campaignSpecification,
      },
      {
        Header: t('common.field.actualData'),
        accessor: TableAccessors.actualData,
      },
      {
        Header: t('common.field.report'),
        accessor: TableAccessors.report,
      },
      {
        Header: t('common.field.matchIO'),
        accessor: TableAccessors.matchIO,
        Cell: ({
          value,
        }: {
          value: { value: MatchIO; name: MatchIOFields } | null;
        }) =>
          value ? (
            <MatchIOSelect
              value={{
                value: value.value,
                label: t(`common.field.${value.value}`),
              }}
              name={value?.name}
              onChangeMatchIOStatus={onChangeStatus}
            />
          ) : (
            <Select
              options={[]}
              styles={authSelectStyles}
              placeholder={t('common.field.not-applicable')}
              components={{
                DropdownIndicator,
              }}
              className={styles.select}
              isDisabled
            />
          ),
      },
    ],
    [statuses]
  );

  return (
    <div className={styles.profileRowWrapper}>
      <Table
        tableOptions={{ columns: columnsData, data: rowsData }}
        wrapperClassName={styles.table}
        isFirstColumnFixed
      />
    </div>
  );
};

export default PersonCheck;
