import React, { useState } from 'react';
import cn from 'classnames';

import IconSVG from 'components/common/IconSVG';

import { IconsNames } from 'constants/icons';

import styles from './index.module.scss';

type Props = {
  title: string;
  subtitle: string;
  icon: IconsNames;
  children?: React.ReactNode;
  iconStyles?: string;
  rightSideComponent?: React.ReactNode;
};

const Panel = ({
  title,
  subtitle,
  icon,
  children,
  iconStyles,
  rightSideComponent,
}: Props): JSX.Element => {
  const [isOpen, setIsOpen] = useState<boolean>(true);

  const handleSectionClick = () => {
    if (children) {
      setIsOpen(!isOpen);
    }
  };

  return (
    <section className={styles.panelContainer}>
      <div
        className={cn(styles.header, {
          [styles.openedHeader]: isOpen && !!children,
          [styles.panel__expandable]: !!children,
        })}
        onClick={handleSectionClick}
      >
        <div className={styles.leftSideWrapper}>
          <div className={cn(styles.iconWrapper, iconStyles)}>
            <IconSVG name={icon} />
          </div>
          <div className={styles.textWrapper}>
            <span className={styles.title}>{title}</span>
            <span className={styles.subtitle}>{subtitle}</span>
          </div>
        </div>
        {rightSideComponent && isOpen && (
          <div className={styles.rightSideWrapper}>{rightSideComponent}</div>
        )}
      </div>
      {isOpen && children && <div className={styles.content}>{children}</div>}
    </section>
  );
};

export default Panel;
