export const API_ENDPOINTS = {
  googleAuth: '/auth/auth/google/',
  refreshToken: '/auth/auth/refresh/',
  logout: '/auth/auth/logout/',
  profile: '/users/profile/',
  jobTitles: '/users/job-titles/',
  balance: '/users/balance/',
  leadStatistics: '/users/lead-statistics/',
  startQC: '/campaigns/start-qc/',
  submitQC: '/campaigns/submit-qc/',
};
