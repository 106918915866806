import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import cn from 'classnames';
import Lazy from 'yup/lib/Lazy';

import type {
  DeepPartial,
  FieldValues,
  SubmitHandler,
  Path,
} from 'react-hook-form';
import { InputData } from 'types/models';
import type { AxiosError } from 'axios';

import InputField from 'components/AuthForm/InputField';
import LoaderScreen from 'components/common/LoaderScreen';
import IconSVG from 'components/common/IconSVG';
import Error from 'components/common/Error';

import { IconsNames } from 'constants/icons';

import styles from './index.module.scss';

type FormProps<FormValues extends FieldValues> = {
  fields: Array<InputData<FormValues>>;
  formFooter?: JSX.Element;
  onSubmit: SubmitHandler<FormValues>;
  validationSchema: Lazy<any>;
  loading?: boolean;
  submitError?: AxiosError | null;
  defaultValues?: DeepPartial<FormValues>;
  cleanAfterSubmit?: boolean;
  disableChanges?: boolean;
  additionalButton?: JSX.Element;
  formClasses?: string;
  isLightBlueButton?: boolean;
  hideSubmitButton?: boolean;
};

const Form = <FormValues extends FieldValues>({
  fields,
  formFooter,
  onSubmit,
  validationSchema,
  loading,
  submitError,
  defaultValues,
  cleanAfterSubmit,
  disableChanges,
  formClasses,
  hideSubmitButton = false,
}: FormProps<FormValues>) => {
  const methods = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: defaultValues,
  });

  useEffect(() => {
    if (defaultValues) {
      methods.setValue('email' as Path<FormValues>, defaultValues.email);
    }
  }, [defaultValues]);

  const submit = (data: FormValues, event?: React.BaseSyntheticEvent) => {
    event?.preventDefault();
    onSubmit(data);

    if (cleanAfterSubmit) {
      methods.reset();
    }
  };

  return (
    <>
      <FormProvider {...methods}>
        <form
          className={cn(styles.form, formClasses)}
          onSubmit={methods.handleSubmit(submit)}
          id="hook-form"
        >
          <>
            {fields.map(fieldData => {
              const { name } = fieldData;
              return (
                <InputField<FormValues>
                  key={String(name)}
                  data={fieldData}
                  register={methods.register}
                  disableChanges={disableChanges}
                  validationError={methods.formState.errors}
                  formSettings={methods}
                />
              );
            })}

            {formFooter}

            {submitError && submitError?.response?.data && (
              <Error
                message={
                  // @ts-ignore
                  (submitError?.response?.data?.detail as string) || ''
                }
                className={styles.error}
              />
            )}

            {!hideSubmitButton && (
              <div className={styles.buttonWrapper}>
                <button
                  className={styles.submitButton}
                  type="submit"
                  aria-label="submit"
                >
                  <IconSVG name={IconsNames.arrowRight} />
                </button>
              </div>
            )}

            {loading && <LoaderScreen />}
          </>
        </form>
      </FormProvider>
    </>
  );
};

export default Form;
