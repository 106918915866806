import React from 'react';
import cn from 'classnames';

import { ReactComponent as LoadingSVG } from 'assets/images/common/loading.svg';
import { ReactComponent as GoogleSVG } from 'assets/images/auth/google.svg';
import { ReactComponent as ArrowLeftSVG } from 'assets/images/common/arrow-left.svg';
import { ReactComponent as ArrowRightSVG } from 'assets/images/common/arrow-right.svg';
import { ReactComponent as ErrorSVG } from 'assets/images/common/error.svg';
import { ReactComponent as SelectArrowsSVG } from 'assets/images/common/select-arrows.svg';
import { ReactComponent as InfoSVG } from 'assets/images/common/info.svg';
import { ReactComponent as ArrowDownSVG } from 'assets/images/common/arrow-down.svg';
import { ReactComponent as AvatarSVG } from 'assets/images/common/avatar.svg';
import { ReactComponent as StarSVG } from 'assets/images/common/star.svg';
import { ReactComponent as TimerSVG } from 'assets/images/common/timer.svg';
import { ReactComponent as LikeSVG } from 'assets/images/common/like.svg';
import { ReactComponent as DislikeSVG } from 'assets/images/common/dislike.svg';
import { ReactComponent as EditSVG } from 'assets/images/common/pencil.svg';
import { ReactComponent as FlagSVG } from 'assets/images/common/flag.svg';
import { ReactComponent as CheckSVG } from 'assets/images/common/check-mark.svg';
import { ReactComponent as CompanyProfileSVG } from 'assets/images/common/company-profile.svg';
import { ReactComponent as CompanyProfileCheckSVG } from 'assets/images/common/company-profile-check.svg';
import { ReactComponent as PersonSVG } from 'assets/images/common/person.svg';
import { ReactComponent as PersonCheckSVG } from 'assets/images/common/person-check.svg';
import { ReactComponent as SmartphoneCheckSVG } from 'assets/images/common/smartphone-check.svg';
import { ReactComponent as LocationSVG } from 'assets/images/common/location.svg';
import { ReactComponent as GlobeSVG } from 'assets/images/common/globe.svg';
import { ReactComponent as LinkedinSVG } from 'assets/images/common/linkedin.svg';
import { ReactComponent as CloseCircleSVG } from 'assets/images/common/close-circle.svg';
import { ReactComponent as CloseSVG } from 'assets/images/common/close.svg';
import { ReactComponent as QuestionCircleSVG } from 'assets/images/common/question-circle.svg';
import { ReactComponent as PhoneSVG } from 'assets/images/common/phone.svg';
import { ReactComponent as ListSVG } from 'assets/images/common/list.svg';
import { ReactComponent as ChevronDownSVG } from 'assets/images/common/chevron-down.svg';
import { ReactComponent as HelloSVG } from 'assets/images/common/hello.svg';
import { ReactComponent as AlignedListSVG } from 'assets/images/common/aligned-list.svg';

import { IconsNames } from 'constants/icons';

import styles from './index.module.scss';

export interface IconProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  name: IconsNames;
}

const IconMap = {
  [IconsNames.loading]: LoadingSVG,
  [IconsNames.google]: GoogleSVG,
  [IconsNames.arrowLeft]: ArrowLeftSVG,
  [IconsNames.arrowDown]: ArrowDownSVG,
  [IconsNames.error]: ErrorSVG,
  [IconsNames.arrowRight]: ArrowRightSVG,
  [IconsNames.selectArrows]: SelectArrowsSVG,
  [IconsNames.info]: InfoSVG,
  [IconsNames.avatar]: AvatarSVG,
  [IconsNames.star]: StarSVG,
  [IconsNames.timer]: TimerSVG,
  [IconsNames.like]: LikeSVG,
  [IconsNames.dislike]: DislikeSVG,
  [IconsNames.edit]: EditSVG,
  [IconsNames.flag]: FlagSVG,
  [IconsNames.check]: CheckSVG,
  [IconsNames.companyProfile]: CompanyProfileSVG,
  [IconsNames.companyProfileCheck]: CompanyProfileCheckSVG,
  [IconsNames.person]: PersonSVG,
  [IconsNames.personCheck]: PersonCheckSVG,
  [IconsNames.smartphoneCheck]: SmartphoneCheckSVG,
  [IconsNames.location]: LocationSVG,
  [IconsNames.globe]: GlobeSVG,
  [IconsNames.linkedin]: LinkedinSVG,
  [IconsNames.closeCircle]: CloseCircleSVG,
  [IconsNames.close]: CloseSVG,
  [IconsNames.questionCircle]: QuestionCircleSVG,
  [IconsNames.phone]: PhoneSVG,
  [IconsNames.list]: ListSVG,
  [IconsNames.chevronDown]: ChevronDownSVG,
  [IconsNames.hello]: HelloSVG,
  [IconsNames.alignedList]: AlignedListSVG,
};

const IconSVG = ({ name, className, ...props }: IconProps): JSX.Element => {
  const Icon = IconMap[name];

  return (
    <span {...props} className={cn(className, styles.icon)}>
      <Icon className={styles.icon} />
    </span>
  );
};

export default IconSVG;
